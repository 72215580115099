@import "variables";

.dc-thorough-pagination {
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  .tp-go-to-page {
    flex: initial;
    margin: 0 8px;
    input[type="text"] {
      border: none;
      border-bottom: 1px solid #333;
      width: 16px;
      height: 16px;
      color: #333;
      line-height: 1;
      background: transparent;
      margin: 0 4px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  }
  .tp-rows-per-page {
    .dc-dropdown-type1 {
      margin: 0 0 0 6px;
    }
  }
  .tp-pages {
    span {
      display: inline-block;
      margin: 0 8px;
    }
    a {
      display: inline-block;
      margin: 0 4px;
      color: $primary;
      text-decoration: underline;
      &.first {
        &:before {
          content: "\e933\e933";
          display: inline-block;
          margin: 0 3px 0 0;
          font: normal 0.75em/1 $icons;
          letter-spacing: -3px;
        }
      }
      &.last {
        &:after {
          content: "\e936\e936";
          display: inline-block;
          margin: 0 0 0 2px;
          font: normal 0.75em/1 $icons;
          letter-spacing: -3px;
        }
      }
      &.prev {
        &:before {
          content: "\e933";
          display: inline-block;
          margin: 0 1px 0 0;
          font: normal 0.75em/1 $icons;
          letter-spacing: 0;
        }
      }
      &.next {
        &:after {
          content: "\e936";
          display: inline-block;
          margin: 0 0 0 1px;
          font: normal 0.75em/1 $icons;
          letter-spacing: 0;
        }
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      &:hover {
        text-decoration: none;
        opacity: 0.5;
      }
    }
  }
}

.dc-pagination__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.dc-pagination {
  display: block;
  width: 33%;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      float: left;
      margin: 0;
      padding: 0;
      + li {
        margin: 0 0 0 8px;
      }
      a {
        cursor: pointer;
        @extend .center-flex;
        color: $primary;
        border-radius: $borderRadius;
        font-size: 14px;
        font-weight: 400;
        padding: 6px;
        min-width: 28px;
        transition: $transition;
        &:before {
          display: block;
          font: 400 1em/1 navus-platform;
        }
        &:hover {
          color: #fff;
          background-color: $primary;
        }
        &.active,
        &.active:hover {
          cursor: default;
          color: #fff;
        }
      }
      &.first {
        a {
          &:before {
            content: "\e930";
            font-family: $icons;
            transform: rotate(90deg);
          }
        }
      }
      &.previous {
        a {
          &:before {
            content: "\e933";
            font-family: $icons;
          }
        }
      }
      &.next {
        a {
          &:before {
            content: "\e936";
            font-family: $icons;
          }
        }
      }
      &.last {
        a {
          &:before {
            content: "\e930";
            font-family: $icons;
            transform: rotate(270deg);
          }
        }
      }
      &.disabled {
        a {
          color: $gray60 !important;
          opacity: 0.5;
          pointer-events: none;
        }
      }
      &.active {
        a,
        a:hover {
          cursor: default;
          color: #fff;
          background-color: $primary;
        }
      }
    }
  }
}

.dc-per-page {
  min-width: 100px;
  width: 33%;
  dc-input {
    float: right;
    max-width: 80px;
    .dc-input {
      width: 80px;
    }
  }
  dc-select {
    float: right;
    .dc-select {
      width: 100px;
    }
  }
}

.dc-total-results {
  span {
    color: $gray60;
    font-size: 13px;
  }
}

@media screen and (max-width: 1020px) {
  .dc-per-page,
  .dc-pagination {
    width: 80%;
  }
}

@media screen and (max-width: 760px) {
  .dc-pagination__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .dc-pagination,
  .dc-total-results {
    margin-bottom: 16px;
  }
  .dc-per-page,
  .dc-pagination,
  .dc-total-results {
    display: flex;
    justify-content: flex-start;
  }
}
