@import "variables";

body {
  &.dc-dark {
    .dc-button {
      &.outline {
        background-color: transparent;
        border: solid 2px $primary;
        span {
          color: $primary;
        }
        &[disabled] {
          background-color: #fff;
          border-color: $gray22;
          opacity: 0.6;
        }
        &:hover {
          .dc-button__text, span, &:before {
            color: $gray22 !important;
          }
          border-color: $gray22;
        }
      }
      &.dc-icon {
        &.outline {
          &:before {
            color: $primary;
          }
        }
      }
    }
  }
}

dc-button {
  &[disabled] {
    pointer-events: none;
  }
}

.dc-button {
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 6px 16px;
  background: transparent;
  border-radius: 8px;
  border: solid 2px #ffffff;
  color: #fff;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  pointer-events: auto;
  &:hover {
    transition: background-color 0.5s, border-color 0.5s, color 0.5s;
  }
  span {
    width: 100%;
    font-family: Prompt, sans-serif;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    line-height: 0;
    white-space: nowrap;
    z-index: 1;
  }
  &.small {
    height: 36px;
    line-height: 17px;
    span {
      font-size: 13px;
    }
    &.dc-icon {
      &:before {
        font-size: 13px;
      }
    }
    &.dc-button__loading {
      &:after {
        font: normal 400 24px dc-icons, sans-serif;
      }
    }
  }
  &.full {
    width: 100%;
  }
  &.half {
    width: 50%;
  }
  &.primary {
    background-color: $primary;
    border: solid 2px $primary;
    color: #fff;
    &[disabled] {
      background-color: $primary;
      opacity: 0.6;
    }
    &.active {
      background-color: $primaryDarker;
      border: solid 2px $primaryDarker;
    }
    &:hover {
      background-color: $primaryDarker;
      border: solid 2px $primaryDarker;
    }
  }
  &.secondary {
    border: solid 2px $primary;
    span {
      color: $primary;
    }
    &[disabled] {
      border-color: $primary;
      opacity: 0.6;
    }
    &.active {
      .dc-button__text {
        color: $primaryDarker;
      }
      border-color: $primaryDarker;
    }
    &:hover {
      .dc-button__text, span, &:before {
        color: $primaryDarker !important;
      }
      border-color: $primaryDarker;
    }
  }
  &.outline {
    background-color: transparent;
    border: solid 2px $gray22;
    span {
      color: $gray60;
    }
    &[disabled] {
      background-color: #fff;
      border-color: $gray22;
      opacity: 0.6;
    }
    &.active {
      border-color: $gray80;
    }
    &:hover {
      .dc-button__text, span, &:before {
        color: $primary !important;
      }
      border-color: $primary;
    }
  }
  &.white {
    background-color: #fff;
    border: solid 2px transparent;
    span {
      color: $primary;
    }
    &[disabled] {
      opacity: 0.6;
    }
    &.active {
      .dc-button__text {
        color: $primaryDarker;
      }
    }
    &:hover {
      .dc-button__text, span, &:before {
        color: $primaryDarker !important;
      }
    }
  }
  &.mobile {
    span {
      font-weight: 500;
    }
  }
  &.dc-icon {
    display: flex;
    align-items: center;
    &:before {
      padding-right: 4px;
      z-index: 1;
    }
    &.white, &.secondary {
      &:before {
        color: $primary;
      }
      &.active {
        &:before {
          color: $primaryDarker;
        }
      }
    }
    &.outline {
      &:before {
        color: $gray60;
      }
      &.active {
        &:before {
          color: $gray80;
        }
      }
    }
  }
  &[disabled] {
    pointer-events: none;
  }
  &.dc-button__loading {
    position: relative;
    > span {
      color: transparent !important;
      visibility: hidden;
    }
    pointer-events: none;
    &.dc-icon {
      &:before {
        display: none;
      }
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      content: '\e944';
      color: #fff;
      font: normal 400 1.275em/1 dc-icons, sans-serif;
      animation: rotating 2000ms infinite linear;
    }
    &.outline {
      &:after {
        color: $gray60;
      }
    }
    &.white {
      &:after {
        color: $primary;
      }
    }
  }
}

.dc-button-gender {
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  background: #fff;
  border: none;
  position: relative;
  &.female {
    box-shadow: 0 0 8px 0 rgba(244, 145, 209, 0.6);
  }
  &.male {
    box-shadow: 0 0 8px 0 rgba(102, 178, 255, 0.6);
  }
}

.dc-button-gps {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  width: fit-content;
  cursor: pointer;
  &:before {
    width: 26px;
    height: 26px;
    border-radius: 8px;
    padding: 3px;
    border: solid 1px $gray22;
    background-color: #fff;
    position: relative;
    font-size: 26px;
    font-family: dc-icons, sans-serif;
    color: $gray22;
    content: '\e91e';
  }
  &.active {
    &:before {
      width: 26px;
      height: 26px;
      border-radius: 8px;
      padding: 3px;
      border: solid 1px $gray22;
      background-color: #fff;
      position: relative;
      font-family: dc-icons, sans-serif;
      color: $primary;
      content: '\e91f';
    }
  }
}

.dc-segment {
  color: #3798f9;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  background-color: #fff;
  padding: 4px 24px;
  cursor: pointer;
  &.active {
    background: #3798f9;
    color: #fff;
    .dc-button__text {
      color: #fff;
    }
  }
}

/* Loading animation on button */
@keyframes rotating {
  0% {
    transform: rotate3d(0,0,1,0);
  }
  100% {
    transform: rotate3d(0,0,1,360deg);
  }
}
