@import "variables";

body {
  &.dc-dark {
    .dc-select {
      .dc-select__control-display {
        background-color: $darkLight;
        border-color: $darkSuperLight;
        .dc-select__value {
          color: $gray60;
        }
        &:after {
          color: $primary;
        }
        .dc-select__deselectAll {
          background-color: $gray60;
          &:after {
            color: $darkPrimary;
          }
        }
      }
    }
    .dc-select-overlay-container {
      .dc-select__list-container {
        background: $darkPrimary;
        color: $gray60;
        .dc-select__options-container {
          li {
            .option-text {
              color: $gray60;
            }
            &:hover {
              .option-text {
                color: #fff;
              }
            }
          }
        }
        .search {
          input {
            background-color: $darkLight;
          }
        }
      }
    }
  }
}

dc-select {
  position: relative;
  outline: none;
  &.readOnly {
    .dc-select {
      .dc-select__control-display {
        border-color: transparent !important;
        background-color: transparent !important;
        pointer-events: none;
        &:after {
          display: none;
        }
      }
    }
  }
}

.dc-select {
  margin-top: 8px;
  .dc-select__control-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 22px;
    border: 1px solid $gray22;
    border-radius: 8px;
    background-color: #fff;
    padding: 19px 4px 19px 12px;
    color: $gray60;
    cursor: pointer;
    outline: none;
    transition: border .3s ease-out;
    overflow: hidden;
    &:focus {
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary;
    }
    &:hover {
      .dc-select__deselectAll {
        display: flex;
      }
    }
    &:after {
      content: '\e92d';
      font-family: dc-icons, sans-serif;
      font-size: 32px;
    }
    &.open {
      &:after {
        content: '\e937';
      }
    }
    &.error {
      background-color: $secondary20;
      box-shadow: 0 0 0 1px $secondary;
      border-color: $secondary;
    }
    &.disabled {
      background-color: $gray10;
      border-color: $gray10;
    }
    &.required-full {
      border-color: $primary;
      background: $primary20;
    }
    &.small {
      height: 16px;
    }
    &.readOnly {
      border: none;
      background: transparent !important;
      pointer-events: none;
      &:focus {
        box-shadow: none;
      }
      &:after {
        content: '';
      }
    }
    .dc-select__deselectAll {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 36px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $gray22;
      &:after {
        content: "\e93d";
        font-family: $icons;
        color: #fff;
        font-size: 10px;
      }
    }
    .dc-select__value {
      font-size: 14px;
      color: $gray80;
      font-weight: 400;
    }
    .dc-select__multiple-value {
      width: calc(100% - 64px);
      overflow: hidden;
      white-space: nowrap;
      .selected-option {
        display: inline-flex;
        height: 40px;
        align-items: center;
        font-size: 14px;
      }
    }
    .dc-select__placeholder {
      display: inline-block;
      width: calc(100% - 60px);
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .dc-select__list-container {
    position: absolute;
    background: #fff;
    border-radius: 8px;
    margin-top: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    z-index: 9999999999;
    overflow: hidden;
    max-height: 0;
    transition: max-height .2s linear;
    &.dc-show {
      max-height: 400px;
    }
    &.clearing {
      opacity: 0;
    }
    .dc-close__list {
      display: none;
      width: 100%;
      align-items: center;
      color: $gray80;
      font-size: 24px;
      padding: 8px 16px;
      .close {
        &:before {
          display: block;
          content: '\e93d';
          font-family: dc-icons, sans-serif;
          font-size: 28px;
        }
      }
      .label {
        margin: 16px 0;
      }
    }
    .responsive-actions {
      display: none;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 100%;
      padding: 8px 16px;
      .button-clear {
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;
      }
      .button-confirm {
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;
      }
    }
    .search {
      display: flex;
      input {
        height: 40px;
        width: 100%;
        padding: 0 12px;
        border: none;
        outline: none;
        font-size: 14px;
        color: $gray60;
        font-family: Rubik, sans-serif;
      }
    }
    .empty, .not-found {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;
      text-align: center;
      font-size: 13px;
    }
    .empty {
      span {
        font-size: 14px;
        color: $gray60;
      }
    }
    .dc-select__options-container {
      overflow: hidden !important;
      max-height: 240px !important;
      position: relative;
      padding: 0;
      margin: 0;
      list-style: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      ul {
        position: relative;
        :not(:first-child) {
          margin-top: 1px;
        }
      }
      li {
        padding: 8px 12px;
        cursor: pointer;
        .option-text {
          color: $gray80;
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
        }
        &:hover {
          background-color: $primary;
          input {
            &::before {
              color: transparent;
            }
          }
          .option-text {
            color: #fff;
            font-weight: 500;
          }
        }
      }
      .add {
        display: flex;
        justify-content: center;
        margin: 8px 12px;
        &:hover {
          background-color: transparent !important;
        }
        button {
          height: 32px;
          &:before {
            font-size: 16px;
          }
          span {
            line-height: normal;
            font-size: 13px;
            color: #fff;
          }
        }
      }
      .option {
        display: flex;
        align-items: center;
        label {
          display: flex;
        }
        input {
          height: 24px;
          margin: 0 20px 0 0;
          font-family: dc-icons, sans-serif;
          &::before {
            content: '\e929';
            font-size: 24px;
            height: 24px;
            position: absolute;
            background: #fff;
            color: $gray22;
            border-radius: 8px;
          }
          &:checked {
            &::after {
              content: '\e928';
              font-size: 24px;
              height: 24px;
              position: absolute;
              color: $primary;
              border-radius: 8px;
            }
          }
        }
        &.checked {
          background-color: $primary20;
          .option-text {
            font-weight: 500;
          }
        }
        &.checked-dark {
          background-color: $primary;
          .option-text {
            color: #fff;
            font-weight: 500;
          }
        }
        .option-content {
          display: flex;
          align-items: center;
          .option-color {
            height: 24px;
            width: 24px;
            border-radius: 6px;
            border: 1px solid #fff;
            margin-right: 8px;
          }
          .option-image {
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            height: 56px;
            width: 56px;
            border-radius: 4px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  &.dc-select__color {
    .dc-select__control-display {
      .option-color-single {
        background-color: $gray22;
        position: absolute;
        height: 56px;
        width: 24px;
        left: 4px;
        border-radius: 5px;
        transition: background-color .2s linear;
      }
      .option-color-multi {
        display: inline-block;
        background-color: $gray22;
        height: 24px;
        width: 24px;
        border-radius: 8px;
      }
    }
    &.large {
      .dc-select__control-display {
        padding: 31px 4px 31px 36px;
      }
    }
    .dc-select__multiple-value {
      .selected-option {
        .option-text {
          margin: 0 4px;
        }
      }
    }
  }
  &.dc-select__image {
    .dc-select__control-display {
      .option-image-single {
        background-color: $gray22;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        position: absolute;
        height: 56px;
        width: 56px;
        left: 4px;
        border-radius: 4px;
        &.fadeIn {
          animation: fadeIn .3s linear;
        }
      }
    }
    &.large {
      .dc-select__control-display {
        padding: 32px 4px 32px 68px;
      }
    }
  }
}

.dc-select__label {
  position: relative;
  font-size: 14px;
  color: $gray60;
  font-weight: 500;
  line-height: 24px;
  .required {
    color: $secondary;
    margin-left: 4px;
    position: absolute;
    top: -4px;
  }
}

.event-disabled {
  pointer-events: none !important;
}

.dc-select-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1070;
  .dc-select__list-container {
    position: absolute;
    background: #fff;
    border-radius: 8px;
    margin-top: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    z-index: 9999999999;
    overflow: hidden;
    opacity: 0;
    max-height: 400px;
    transform: translateY(15px);
    transition: opacity .3s linear, transform .2s linear;
    pointer-events: none;
    &.dc-show {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
    &.clearing {
      opacity: 0;
    }
    .dc-close__list {
      display: none;
      width: 100%;
      align-items: center;
      color: $gray80;
      font-size: 24px;
      padding: 8px 16px;
      .close {
        &:before {
          display: block;
          content: '\e93d';
          font-family: dc-icons, sans-serif;
          font-size: 28px;
        }
      }
      .label {
        margin: 16px 0;
      }
    }
    .responsive-actions {
      display: none;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 100%;
      padding: 8px 16px;
      .button-clear {
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;
      }
      .button-confirm {
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;
      }
    }
    .search {
      display: flex;
      input {
        height: 40px;
        width: 100%;
        padding: 0 12px;
        border: none;
        outline: none;
        font-size: 14px;
        color: $gray60;
        font-family: Rubik, sans-serif;
      }
    }
    .empty, .not-found {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px;
      text-align: center;
      font-size: 13px;
    }
    .empty {
      span {
        font-size: 14px;
        color: $gray60;
      }
    }
    .dc-select__options-container {
      overflow: hidden !important;
      max-height: 240px !important;
      position: relative;
      padding: 0;
      margin: 0;
      list-style: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      ul {
        position: relative;
        :not(:first-child) {
          margin-top: 1px;
        }
      }
      li {
        padding: 8px 12px;
        cursor: pointer;
        .option-text {
          color: $gray80;
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
        }
        &:hover {
          background-color: $primary;
          input {
            &::before {
              color: transparent;
            }
          }
          .option-text {
            color: #fff;
            font-weight: 500;
          }
        }
      }
      .add {
        display: flex;
        justify-content: center;
        margin: 8px 12px;
        &:hover {
          background-color: transparent !important;
        }
        button {
          height: 32px;
          &:before {
            font-size: 16px;
          }
          span {
            line-height: normal;
            font-size: 13px;
            color: #fff;
          }
        }
      }
      .option {
        display: flex;
        align-items: center;
        label {
          display: flex;
        }
        input {
          height: 24px;
          margin: 0 20px 0 0;
          font-family: dc-icons, sans-serif;
          &::before {
            content: '\e929';
            font-size: 24px;
            height: 24px;
            position: absolute;
            background: #fff;
            color: $gray22;
            border-radius: 8px;
          }
          &:checked {
            &::after {
              content: '\e928';
              font-size: 24px;
              height: 24px;
              position: absolute;
              color: $primary;
              border-radius: 8px;
            }
          }
        }
        &.checked {
          background-color: $primary20;
          .option-text {
            font-weight: 500;
          }
        }
        &.checked-dark {
          background-color: $primary;
          .option-text {
            color: #fff;
            font-weight: 500;
          }
        }
        .option-content {
          display: flex;
          align-items: center;
          .option-color {
            height: 24px;
            width: 24px;
            border-radius: 6px;
            border: 1px solid #fff;
            margin-right: 8px;
          }
          .option-image {
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            height: 56px;
            width: 56px;
            border-radius: 4px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 660px){
  dc-select {
    perfect-scrollbar {
      .ps {
        max-height: 100% !important;
      }
    }
  }
  .dc-select {
    .dc-select__control-display {
      .dc-select__deselectAll {
        display: flex;
      }
      &:hover {
        .dc-select__deselectAll {
          display: flex;
        }
      }
    }
  }
  .dc-select-overlay-container {
    .dc-select__list-container {
      margin-top: 0;
      width: 100% !important;
      height: 100%;
      top: 0 !important;
      z-index: 30002 !important;
      left: 0;
      border-radius: 0;
      position: fixed;
      &.dc-show {
        max-height: 100%;
      }
      .dc-select__options-container {
        height: 89%;
        padding-bottom: 32px;
        .ps-content {
          .option {
            border-bottom: 1px solid $gray60;
            padding: 16px;
            margin-top: 0;
            .option-text {
              font-size: 16px;
            }
            .option-content {
              .option-image {
                height: 72px;
                width: 72px;
              }
            }
          }
        }
      }
      .dc-close__list {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background-color: $primary;
        color: #fff;
      }
      .responsive-actions {
        display: flex;
        background: #fff;
        position: absolute;
        bottom: 0;
        padding: 24px 16px;
        .button-confirm {
          &.show {
            opacity: 1;
            pointer-events: all;
          }
          button {
            font-size: 16px;
            font-family: Prompt, sans-serif;
            color: $primary;
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
        .button-clear {
          &.show {
            opacity: 1;
            pointer-events: all;
          }
          button {
            font-size: 16px;
            font-family: Prompt, sans-serif;
            color: $gray60;
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
      }
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
