@import "variables";

body {
  &.dc-dark {
    owl-date-time-calendar {
      background-color: $darkLighter;
      .owl-dt-calendar-control {
        color: $darkPrimary;
      }
      .owl-dt-calendar-header {
        tr {
          th {
            color: $darkPrimary;
          }
        }
      }
      .owl-dt-control-button-arrow {
        svg {
          fill: $darkPrimary;
        }
      }
    }
    owl-date-time-container {
      background-color: $darkLighter !important;
    }
    .cdk-overlay-container {
      .cdk-overlay-pane {
        background-color: $darkLightest !important;
      }
    }
    owl-date-time-multi-year-view {
      .owl-dt-calendar-table {
        &.owl-dt-calendar-multi-year-table {
          .owl-dt-calendar-header {
            background-color: $darkLighter;
            tr {
              th {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .owl-dt-calendar-cell-content {
      border-color: $darkLighter;
      background-color: $darkLighter !important;
      color: #fff;
      &.owl-dt-calendar-cell-today {
        border-color: $primary;
      }
      &.owl-dt-calendar-cell-selected {
        background: $primary60 !important;
        border-color: $primary60;
      }
    }
    .dc-datepicker {
      input {
        background-color: $darkLight;
        border-color: $darkSuperLight;
        color: $gray60;
      }
      .dc-datepicker__icon {
        &:before {
          color: $primary;
        }
      }
      .dc-datepicker__clear {
        background-color: $gray60;
        &:after {
          color: $darkPrimary;
        }
      }
    }
  }
}

owl-date-time-calendar {
  height: auto !important;
  .owl-dt-calendar-control {
    background-color: $primary;
    color: #fff;
    .owl-dt-control-button {
      span {
        font-family: Prompt, sans-serif;
      }
    }
  }
  .owl-dt-calendar-main {
    padding: 0;
  }
  .owl-dt-calendar-header {
    background-color: $primary;
    tr {
      th {
        color: #fff;
        text-transform: uppercase;
      }
    }
    .owl-dt-calendar-table-divider {
      display: none;
    }
  }
  .owl-dt-control-button-arrow {
    svg {
      fill: #fff;
    }
  }
}
owl-date-time-timer {
  .owl-dt-timer-content {
    .owl-dt-timer-input {
      border-radius: 8px;
      padding: 4px;
      width: 40px;
      height: 32px;
      font-size: 14px;
      color: $gray80;
    }
  }
}
owl-date-time-multi-year-view {
  .owl-dt-calendar-table {
    &.owl-dt-calendar-multi-year-table {
      .owl-dt-calendar-header {
        background-color: #fff;
        tr {
          th {
            color: $gray60;
          }
        }
      }
    }
  }
}
owl-date-time-container {
  border-radius: 8px;
  overflow: hidden;
}
.cdk-overlay-container {
  z-index: 9999;
  .cdk-overlay-pane {
    margin: 4px 0;
  }
}
.owl-dt-container-buttons {
  height: 60px;
  display: flex;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid;
    margin: 8px;
    &:nth-child(1) {
      color: $gray60;
      span {
        color: $gray60;
      }
      &:hover {
        color: $gray80;
        span {
          color: $gray80;
        }
      }
    }
    &:nth-child(2) {
      color: $primary;
      background-color: $primary;
      span {
        color: #fff;
      }
      &:hover {
        background-color: $primaryDarker;
      }
    }
  }
}
.owl-dt-calendar-cell-content {
  border-radius: 8px;
  border-color: $gray60;
  background-color: #fff !important;
  &:hover {
    border-color: $primary !important;
    border-radius: 8px !important;
    background-color: $primary !important;
    color: #fff !important;
  }
  &.owl-dt-calendar-cell-today {
    border-radius: 8px;
    border-color: $gray60;
    background-color: #fff !important;
    &:hover {
      border-color: $primary !important;
      border-radius: 8px !important;
      background-color: $primary !important;
      color: #fff !important;
    }
  }
  &.owl-dt-calendar-cell-selected {
    border-radius: 8px !important;
    background: $primary !important;
    box-shadow: none !important;
    border-color: $primary;
  }
}

dc-datepicker {
  outline: none;
  &.transparent {
    &.text-right {
      label, input {
        text-align: right;
        padding: 0;
      }
    }
    input {
      &[disabled] {
        border: none;
        background: transparent;
        color: $gray80;
      }
    }
  }
}
.dc-datepicker {
  position: relative;
  cursor: pointer;
  //z-index: 1060;
  label {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  input {
    height: 40px;
    border-radius: 8px;
    border: solid 1px $gray22;
    background-color: #fff;
    padding:0 12px;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    color: $gray80;
    transition: border 300ms ease-out, background-color 300ms ease-out;
    cursor: pointer;
    &:focus {
      border: solid 1px $primary;
      box-shadow: 0px 0px 0px 1px $primary;
      border-radius: 8px;
      outline: none;
    }
    &.requiredFull {
      border: solid 1px $primary;
      background-color: $primary20;
    }
    &.error {
      border: solid 1px $secondary;
      box-shadow: 0px 0px 0px 1px $secondary;
      background-color: $secondary20;
    }
    &[disabled] {
      border: solid 1px $gray10;
      background-color: $gray10;
      color: $gray60;
    }
    &[readonly] {
      border: none;
      background: transparent !important;
      pointer-events: none;
      &:focus {
        box-shadow: none;
      }
    }
    &::placeholder {
      color: $gray60;
    }
  }
  &:hover {
    .dc-datepicker__clear {
      display: flex;
    }
  }
  &.readOnly {
    .dc-datepicker__icon, .dc-datepicker__clear {
      visibility: hidden;
    }
  }
  .dc-datepicker__label {
    display: block;
    font-size: 14px;
    color: $gray60;
    font-weight: 500;
    font-family: Rubik, sans-serif;
    margin-bottom: 8px;
    .required {
      color: $secondary;
      margin-left: 4px;
      position: absolute;
      top: -4px;
    }
  }
  .dc-datepicker__icon {
    position: absolute;
    right: 4px;
    bottom: 8px;
    font-size: 32px;
    cursor: pointer;
  }
  .dc-datepicker__clear {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $gray22;
    bottom: 12px;
    &.showIcon {
      right: 48px;
    }
    &:after {
      content: "\e93d";
      font-family: $icons;
      color: #fff;
      font-size: 10px;
    }
  }
}
.dc-datepicker__error-box {
  .dc-datepicker__error {
    &.single {
      span {
        color: $secondary;
        font-size: 13px;
        line-height: 16px;
      }
    }
    &.multi {
      span {
        cursor: pointer;
        line-height: 16px;
        color: $secondary;
        font-size: 13px;
      }
      ul {
        li {
          color: $secondary;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .cdk-overlay-container {
    .cdk-overlay-pane {
      transform: none !important;
      top: 0 !important;
      left: 0 !important;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
  owl-date-time-container {
    border-radius: 0;
    width: 100% !important;
    height: 100%;
  }
}
