@import "variables";
body {
  &.dc-dark {
    .dc-input__wrapper {
      .dc-input {
        input {
          background-color: $darkLight;
          border-color: $darkSuperLight;
          color: $gray60;
        }
        &.search {
          input {
            &:focus {
              border: solid 1px $primaryDarker;
            }
            &.filled {
              &:focus {
                border: solid 1px $primaryDarker;
              }
            }
          }
          .search-icon {
            background-color: $primaryDarker;
            color: $darkPrimary;
            &.filled {
              background-color: $primary;
            }
          }
          .clearSearchTerm {
            background-color: $gray60;
            &:before {
              color: $darkPrimary;
            }
          }
        }
      }
    }
  }
  &.mobile {
    dc-input {
      .dc-input__wrapper {
        .dc-input {
          font-size: 15px;
          &.search {
            input {
              height: 39px;
              border-radius: 20px;
              padding: 1px 12px 0 40px;
              font-size: 15px;
              border: none;
              &.large {
                height: 47px;
                padding: 1px 12px 0 40px;
                border-radius: 24px;
                font-size: 16px;
              }
              &:focus {
                border: none;
              }
            }
          }
          .search-icon {
            position: absolute;
            left: 0;
            border-radius: 8px 0 0 8px;
            height: 39px;
            background-color: transparent;
            &:before {
              content: '\e926';
              font-family: $icons;
              font-size: 32px;
              color: $gray60;
            }
            &.filled {
              background-color: transparent;
            }
            &.large {
              height: 48px;
            }
          }
          .clearSearchTerm {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 16px;
            height: 24px;
            width: 24px;;
            border-radius: 50%;
            background-color: $gray10;
            &:before {
              content: "\e93d";
              font-family: $icons;
              color: $gray60;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
dc-input {
  position: relative;
  width: 100%;
}
.dc-input__wrapper {
  .search-reset {
    display: none;
  }
  .dc-input {
    display: flex;
    flex-direction: column;
    position: relative;
    input {
      height: 40px;
      border-radius: 8px;
      border: solid 1px $gray22;
      background-color: #fff;
      padding:0 12px;
      font-family: Rubik, sans-serif;
      font-size: 14px;
      color: $gray80;
      transition: border 300ms ease-out, background-color 300ms ease-out;
      &:focus {
        border: solid 1px $primary;
        box-shadow: 0 0 0 1px $primary;
        border-radius: 8px;
        outline: none;
      }
      &.required {
        border: solid 1px $primary;
        background-color: $primary20;
      }
      &.error {
        border: solid 1px $secondary;
        box-shadow: 0 0 0 1px $secondary;
        background-color: $secondary20;
      }
      &[disabled] {
        border: solid 1px $gray10;
        box-shadow: 0 0 0 1px $gray10;
        background-color: $gray10;
        color: $gray60;
      }
      &[readOnly] {
        border: none;
        background: transparent !important;
        &:focus {
          box-shadow: none;
        }
      }
      &::placeholder {
        color: $gray60;
      }
    }
    textarea {
      border-radius: 8px;
      border: solid 1px $gray22;
      background-color: #fff;
      padding:8px 12px;
      font-family: Rubik, sans-serif;
      font-size: 14px;
      line-height: 24px;
      color: $gray80;
      transition: border 300ms ease-out, background-color 300ms ease-out;
      &:focus {
        border: solid 1px $primary;
        box-shadow: 0px 0px 0px 1px $primary;
        border-radius: 8px;
        outline: none;
      }
      &.required {
        border: solid 1px $primary;
        background-color: $primary20;
      }
      &.error {
        border: solid 1px $secondary;
        box-shadow: 0px 0px 0px 1px $secondary;
        background-color: $secondary20;
      }
      &[disabled],&[readOnly] {
        border: solid 1px $gray10;
        background-color: $gray10;
        color: $gray60;
      }
      &::placeholder {
        color: $gray60;
      }
    }
    .dc-input__label {
      span {
        display: block;
        font-size: 14px;
        color: $gray60;
        font-weight: 500;
        font-family: Rubik, sans-serif;
        margin-bottom: 8px;
        .required {
          color: $secondary;
          margin-left: 4px;
          position: absolute;
          top: -4px;
        }
      }
    }
    .input-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 24px;
      background-color: $primary;
      padding: 8px 16px;
      border-radius: 0 8px 8px 0;
      color: #fff;
      cursor: pointer;
      &:before {
        font-size: 32px;
      }
      &.disabled {
        background-color: $gray22;
      }
    }
    &.search {
      justify-content: center;
      input {
        &:focus {
          border: solid 1px $primary60;
        }
        &.filled {
          &:focus {
            border: solid 1px $primary;
          }
        }
        &[disabled] {
          background: $gray10;
        }
      }
      .search-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        height: 40px;
        width: 48px;
        border-radius: 0 8px 8px 0;
        color: #fff;
        cursor: pointer;
        background-color: $primary60;
        &:before {
          content: '\e926';
          font-family: $icons;
          font-size: 32px;
        }
        &.filled {
          background-color: $primary;
        }
        &.disabled {
          background-color: $gray22;
        }
      }
      .clearSearchTerm {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 54px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $gray22;
        cursor: pointer;
        &:before {
          content: "\e93d";
          font-family: $icons;
          color: #fff;
          font-size: 12px;
        }
      }
    }
    &.mobile {
      &.search {
        input {
          height: 39px;
          border-radius: 20px;
          padding: 1px 12px 0 40px;
          font-size: 15px;
          border: none;
          &.large {
            height: 47px;
            padding: 1px 12px 0 40px;
            border-radius: 24px;
            font-size: 16px;
          }
          &:focus {
            border: none;
          }
          &.filled {
            &:focus,&:not(&.large) {
              height: 39px;
            }
          }
        }
      }
      .search-icon {
        position: absolute;
        left: 0;
        border-radius: 8px 0 0 8px;
        height: 39px;
        background-color: transparent;
        &:before {
          content: '\e926';
          font-family: $icons;
          font-size: 32px;
          color: $gray60;
        }
        &.filled {
          background-color: transparent;
        }
        &.large {
          height: 48px;
        }
      }
      .clearSearchTerm {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 16px;
        height: 24px;
        width: 24px;;
        border-radius: 50%;
        background-color: $gray10;
        &:before {
          content: "\e93d";
          font-family: $icons;
          color: $gray60;
          font-size: 16px;
        }
      }
    }
  }
  .dc-input__error-box {

    .dc-input__error {
      &.single {
        span {
          color: $secondary;
          font-size: 13px;
          line-height: 16px;
        }
      }
      &.multi {
        span {
          cursor: pointer;
          line-height: 16px;
          color: $secondary;
          font-size: 13px;
        }
        ul {
          li {
            color: $secondary;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .dc-input__password-toggle {
    position: absolute;
    right: 0;
    top: 31px;
    width: 35px;
    height: 20px;
    padding-right: 8px;
    margin-top: 10px;
    text-align: center;
    z-index: 1000;
    cursor: pointer;
    &.hide {
      &:before {
        content: '\e93e';
        font-family: dc-icons, sans-serif;
        font-size: 20px;
      }
    }
    &.show {
      &:before {
        content: '\e93f';
        font-family: dc-icons, sans-serif;
        font-size: 20px;
      }
    }
  }
  .dc-input__info-text {
    padding: 4px;
    span {
      font-family: Rubik, sans-serif;
      font-size: 13px;
      line-height: 18px;
      color: $gray60;
    }
  }
}
