body {
  &.dc-dark {
    .dc-empty-state__wrapper {
      .dc-empty-state__content {
        &.campaigns {
          background-image: url("../assets/empty-state/no-campaigns-dark.svg");
        }
        &.calls {
          background-image: url("../assets/empty-state/no-calls-dark.svg");
        }
        &.contacts {
          background-image: url("../assets/empty-state/no-contacts-dark.svg");
        }
        &.operators {
          background-image: url("../assets/empty-state/no-operators-dark.svg");
        }
        &.visits {
          background-image: url("../assets/empty-state/no-visits-dark.svg");
        }
        &.contracts {
          background-image: url("../assets/empty-state/no-documents-dark.svg");
        }
        &.statistic {
          background-image: url("../assets/empty-state/no-statistics-dark.svg");
        }
      }
    }
  }
}

.dc-empty-state__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 250px;
  .dc-empty-state__content {
    width: 50%;
    margin: 0 auto;
    min-height: 125px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &.campaigns {
      background-image: url("../assets/empty-state/no-campaigns.svg");
    }
    &.calls {
      background-image: url("../assets/empty-state/no-calls.svg");
    }
    &.contacts {
      background-image: url("../assets/empty-state/no-contacts.svg");
    }
    &.operators {
      background-image: url("../assets/empty-state/no-operators.svg");
    }
    &.visits {
      background-image: url("../assets/empty-state/no-visits.svg");
    }
    &.contracts {
      background-image: url("../assets/empty-state/no-documents.svg");
    }
    &.statistic {
      background-image: url("../assets/empty-state/no-statistics.svg");
    }
  }
  .dc-empty-state__message {
    text-align: center;
    p {
      font-size: 14px;
      margin: 16px 0;
    }
  }
}
