@import "./variables";
@import "./buttons";
@import "./inputs";
@import "./loading";
@import "./typography";
@import "./elements";
@import "./grid";
@import "./icons";
@import "./checkbox";
@import "./radio";
@import "./switch";
@import "./modal";
@import "./select";
@import "./progress-bar";
@import "./tabs";
@import "./datepicker";
@import "./file-upload";
@import "./auto-upload-modal";
@import "./accordion";
@import "./carousel";
@import "./toast";
@import "./text-editor";
@import "./collapse";
@import "./mapbox";
@import "./table";
@import "./dropdown";
@import "./empty-state";
@import "./avatar";
@import "./timepicker";
@import "./datepicker-mobile";
@import "./pagination";

* {
  box-sizing: border-box;
}

@font-face {
  font-family: Rubik;
  src: url("../assets/fonts/Rubik/Rubik-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Rubik;
  src: url("../assets/fonts/Rubik/Rubik-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Rubik;
  src: url("../assets/fonts/Rubik/Rubik-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Rubik;
  src: url("../assets/fonts/Rubik/Rubik-Bold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Rubik;
  src: url("../assets/fonts/Rubik/Rubik-Black.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Prompt;
  src: url("../assets/fonts/Prompt/Prompt-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Prompt;
  src: url("../assets/fonts/Prompt/Prompt-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Prompt;
  src: url("../assets/fonts/Prompt/Prompt-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Prompt;
  src: url("../assets/fonts/Prompt/Prompt-Bold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Prompt;
  src: url("../assets/fonts/Prompt/Prompt-Black.ttf");
  font-weight: 800;
}

perfect-scrollbar {
  .ps__rail-y {
    .ps__thumb-y {
      background-color: $gray80;
    }
  }
}

.dc-show {
  display: block !important;
}

.dc-hide {
  display: none !important;
}

.position-relative {
  position: relative;
}

.dc__no-scroll {
  overflow: hidden !important;
}

//Extends
.flex {
  display: flex;
}
.align-center-flex {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
}
.justify-center-flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  justify-items: center;
}

.justify-space-between-flex {
  display: flex;
  justify-content: space-between;
}

.justify-start-flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start !important;
  justify-items: flex-start;
}

.center-flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.end-flex-row {
  display: flex;
  flex-direction: row !important;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}
.center-flex-inline {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.space-between-flex-inline {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.sticky-flex {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
}

//Padding & Margin
.padding {
  padding: 16px;
  &.p-small {
    padding: 8px;
  }
}

.padding-left {
  padding-left: 16px;
  &.p-small {
    padding-left: 8px;
  }
}

.padding-right {
  padding-right: 16px;
  &.p-small {
    padding-right: 8px;
  }
}

.padding-top {
  padding-top: 16px;
  &.p-small {
    padding-top: 8px;
  }
}

.padding-bottom {
  padding-bottom: 16px;
  &.p-small {
    padding-bottom: 8px;
  }
}

.padding-vertical {
  padding: 16px 0;
  &.p-small {
    padding: 8px 0;
  }
}

.padding-horizontal {
  padding: 0 16px;
  &.p-small {
    padding: 0 8px;
  }
}

.margin {
  margin: 16px;
  &.m-small {
    margin: 8px;
  }
}

.margin-left {
  margin-left: 16px;
  &.m-small {
    margin-left: 8px;
  }
}

.margin-right {
  margin-right: 16px;
  &.m-small {
    margin-right: 8px;
  }
}

.margin-top {
  margin-top: 16px;
  &.m-small {
    margin-top: 8px;
  }
}

.margin-bottom {
  margin-bottom: 16px;
  &.m-small {
    margin-bottom: 8px;
  }
}

.margin-vertical {
  margin: 16px 0;
  &.m-small {
    margin: 8px 0;
  }
}

.margin-horizontal {
  margin: 0 16px;
  &.m-small {
    margin: 0 8px;
  }
}

.dc-clickable {
  cursor: pointer;
}

.no-event {
  pointer-events: none;
}

.mt-0 {
  margin-top: 0;
}
