@import "variables";

body {
  &.dc-dark {
    .dc-time-picker {
      .dc-time-picker__hours-wrapper, .dc-time-picker__minutes-wrapper {
        background-color: $darkLight;
      }
    }
  }
}

dc-timepicker {
  position: relative;
  perfect-scrollbar {
    .ps {
      max-height: 240px !important;
    }
  }
}
.dc-time-picker {
  position: absolute;
  background: #fff;
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
  max-height: 0;
  transition: max-height .2s linear;
  margin-bottom: 8px;
  width: 100%;
  &.show {
    max-height: 240px;
  }
  .dc-time-picker__header {
    display: flex;
    align-items: center;
    background-color: $primary;
    padding: 16px;
    .dc-time-picker__back {
      position: absolute;
      color: #fff;
      &:before {
        font-size: 0;
      }
      &.show {
        &:before {
          font-size: 32px;
        }
      }
    }
    .dc-time-picker__display {
      width: 100%;
      text-align: center;
      span {
        font-size: 24px;
        font-weight: 500;
        font-family: 'Prompt', sans-serif;
        color: #fff;
      }
      .selected-hour {
        &.active {
          animation: blink-animation 1.2s linear infinite;
        }
      }
      .selected-minutes {
        &.active {
          animation: blink-animation 1.2s linear infinite;
        }
      }
    }
  }
  .dc-time-picker__hours-wrapper {
    height: 200px;
    overflow: hidden;
    position: relative;
    padding: 16px;
    &.hide {
      display: none;
    }
    h3 {
      margin: 0 0 8px 0;
    }
    .dc-time-picker__hours {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;
      transition: opacity .2s linear;
      .hour {
        width: 30%;
        text-align: center;
        padding: 8px;
        margin: 0 4px 8px;
        border: 1px solid $primary;
        border-radius: 20px;
        font-size: 14px;
        color: $gray60;
        transition: background-color .3s linear;
        &:hover {
          background-color: $primary;
          color: #fff;
        }
      }
    }
  }
  .dc-time-picker__minutes-wrapper {
    display: none;
    overflow: hidden;
    position: relative;
    padding: 16px;
    &.show {
      height: 200px;
      display: block;
    }
    h3 {
      margin: 0 0 8px 0;
    }
    .dc-time-picker__minutes {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;
      transition: opacity .2s linear;
      .minute {
        width: 30%;
        text-align: center;
        padding: 8px;
        margin: 0 4px 8px;
        border: 1px solid $primary;
        border-radius: 20px;
        font-size: 14px;
        color: $gray60;
        transition: background-color .3s linear;
        &:hover {
          background-color: $primary;
          color: #fff;
        }
      }
    }
  }
}
@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
