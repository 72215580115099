@import "variables";
body {
  font-family: Rubik, sans-serif;
  font-size: 15px;
  color: $gray80;
  font-weight: 400;
  h2, h3, h4 {
    font-family: Prompt, sans-serif;
    font-weight: 500;
    margin: 0;
  }

  h1 {
    font-size: 20px;
    line-height: 36px;
    letter-spacing: normal;
    font-family: Prompt, sans-serif;
    font-weight: 400;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
  }

  h4 {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: normal;
  }

  p {
    line-height: 14px;
    letter-spacing: 0;
    margin: 0;
  }

  a {
    text-decoration-line: none;
    color: $primary;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: $gray60;
    line-height: 24px;
    letter-spacing: 0;
  }

  hr {
    width: 100%;
  }

  .bold {
    font-weight: 500;
  }

  .underline {
    text-decoration: underline;
  }

  .uppercase {
    text-transform: uppercase;
  }

  //text
  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .super-heading {
    font-family: Prompt, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: $gray22;
    text-transform: uppercase;
  }

  //text-color
  .dc-text-color-primary {
    color: $primary !important;
  }

  .dc-text-color-secondary {
    color: $secondary !important;
  }
}
