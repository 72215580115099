@import "variables";

.dc-dropdown {
  position: relative;
  .dc-dropdown__trigger {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .dc-dropdown__list {
    list-style: none;
    padding: 0;
    margin: 0;
    .dc-dropdown__item {
      display: flex;
      align-items: center;
      padding: 6px 16px;
      font-family: Prompt, sans-serif;
      font-size: 13px;
      cursor: pointer;
      &.primary {
        color: $primary;
        &:hover {
          background: $primary;
          color: #fff;
        }
      }
      &.secondary {
        color: $secondary;
        &:hover {
          background: $secondary;
          color: #fff;
        }
      }
      &.warning {
        color: $warning;
        &:hover {
          background: $warning;
          color: #fff;
        }
      }
      .dc-icon {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
  .dc-dropdown__menu {
    position: absolute;
    padding: 8px 0;
    background: #fff;
    border-radius: $borderRadius;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
    display: none;
    &.show {
      display: block;
      animation: showDrop 0.2s linear;
    }
    &.bottom {
      top: calc(100% + 4px);
    }
    &.top {
      bottom: calc(100% + 4px);
    }
    dc-button {
      width: 100%;
      .dc-button {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 0;
        text-align: left;
        &:before {
          font-size: 20px;
          padding-right: 8px;
        }
        span {
          font-size: 13px;
          text-align: left;
        }
        &.primary {
          background: transparent;
          color: $primary;
          border: none;
          &:hover {
            background: $primary;
            span,  &:before {
              color: #fff;
            }
          }
        }
        &.secondary {
          background: transparent;
          border: none;
          span, &:before {
            color: $secondary;
          }
          &:hover {
            background: $secondary;
            span, &:before {
              color: #fff !important;
            }
          }
        }
      }
    }
  }

  @keyframes showDrop {
    0% {
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }
}
