@import "libs/ui/src/sass/variables";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

* {
  outline: none;
}

::-moz-selection {
  color: #ffffff;
  background: $primary;
}
::selection {
  color: #ffffff;
  background: $primary;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #f4f3f7;
}

app-root {
  display: block;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.dc-root main {
  overflow: hidden auto;
}

.ps__rail-y {
  z-index: 1049;
}

.dc-icon {
  font-family: dc-icons, sans-serif !important;
  font-size: 26px !important;
}

.application {
  .add {
    height: 48px;
    width: 48px;
    background: url("assets/icons/add-contact.svg") center no-repeat;
    background-size: cover;
    &:hover {
      background: url("assets/icons/add-contact-hover.svg") center no-repeat;
    }
  }
  .play {
    height: 56px;
    width: 56px;
    background: url("assets/icons/play.svg") center no-repeat;
    background-size: cover;
  }
  .pause {
    height: 56px;
    width: 56px;
    background: url("assets/icons/pause.svg") center no-repeat;
    background-size: cover;
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.dc-row {
  &.loading-wrapper {
    position: relative !important;
    min-height: 300px !important;
  }
}

.scrollbar-visible {
  &.both {
    .ps__rail-x {
      opacity: 0.6;
    }
  }
  .ps__rail-y {
    opacity: 0.6;
  }
}

main[app-operator-home],
main[app-contact-list],
main[app-blocked-list],
main[app-contract-list],
main[app-employee-list],
main[app-campaign-list],
main[app-visit-list],
main[app-visit-details],
main[app-contact-details],
main[app-employee-details],
main[app-campaign-details],
main[app-contract-details],
main[app-operator-client-details],
main[app-statistic],
main[app-unauthorized],
main[app-operator-statistic] {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    flex: 0 0 auto;
  }

  > .dc-content {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 auto;
  }
}

// Components

ckeditor {
  .ck-content {
    background: #fff;
  }
}

.bnt-card__wrapper {
  position: relative;
  overflow: hidden;
  &.operator-home__height {
    max-height: calc(100vh - 286px);
  }
  &.full-height {
    height: calc(100vh - 360px);
  }
  &.medium-height {
    height: calc(75vh - 360px);
  }
}

.bnt-call__card {
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 56px 16px;
  width: 400px;
  margin: 0 auto 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    color: $gray80;
  }
  .dc-icon {
    color: $primary;
    font-size: 46px;
  }
  .call-loading {
    display: flex;
    padding: 12px 0 24px 0;
    span {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: #d3d3d3;
      margin-right: 8px;
      &.active {
        background-color: $primary;
      }
    }
  }
}

.bnt-client__card {
  background: #fff;
  border-radius: $borderRadius;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    span {
      font-weight: 300;
    }
  }
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    &.call {
      background: url("assets/icons/calls-filled.svg") center no-repeat;
      background-size: cover;
    }
    &.visit {
      background: url("assets/icons/visits-full.svg") center no-repeat;
      background-size: cover;
    }
  }
  .info {
    display: flex;
    width: 55%;
    justify-content: flex-end;
    .info-element {
      width: 120px;
    }
  }
}

.bnt-button-text {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 13px;
  color: $gray60;
  font-family: Prompt, sans-serif;
  .dc-icon {
    position: relative;
    height: 24px;
    width: 24px;
    background-color: $gray22;
    color: #fff;
    border-radius: 50%;
    font-size: 14px !important;
    margin-right: 10px;
    &:before {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
}

.bnt-filter__actions {
  display: flex;
  flex-direction: row !important;
}

.bnt-selected_info {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $gray60;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  text-align: end;
}

.bnt-calls__list {
  max-height: 300px;
  overflow: hidden;
  position: relative;
}

.client__details-call {
  padding: 16px;
  border-radius: $borderRadius;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  .alert-text {
    color: $secondary;
    font-size: 14px;
    font-family: Roboto, sans-serif;
  }
  &.empty {
    vertical-align: middle;
    padding: 100px 0;
  }
  .call-info {
    color: green;
    &:before {
      display: inline-block;
      font-size: 16px;
    }
    &.disconnected {
      color: red;
      &:before {
        -webkit-transform: rotate(-140deg);
        -moz-transform: rotate(-140deg);
        -o-transform: rotate(-140deg);
        -ms-transform: rotate(-140deg);
        transform: rotate(-140deg);
        transition: transform 300ms linear;
      }
    }
  }
}

.bnt-operator__actions {
  display: flex;
  align-items: center;
}

.bnt-operator__call {
  position: fixed;
  top: 200px;
  left: 0;
  right: 0;
}

.bnt-add-modal__filters-wrapper {
  position: relative;
  max-height: 400px;
  padding: 0 12px 0 4px;
}

.bnt-upload-file__info {
  border-radius: 8px;
  background-color: #f1f1f1;
  padding: 0 16px 16px;
  span {
    font-weight: 500;
  }
}

.bnt-pause__info {
  font-size: 13px;
  font-family: Prompt, sans-serif;
}

.dc-button__small-icon {
  button {
    &:before {
      font-size: 17px;
    }
  }
}

.radio-group__wrapper {
  display: flex;
  align-content: center;
  margin-top: 39px;
  dc-radio {
    margin-right: 16px;
  }
}

// Pages

.bnt-login__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("assets/images/login-background.jpg") center no-repeat;
  background-size: cover;
  overflow: auto;
  padding: 80px 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: $gray80;
  }
  h2 {
    font-weight: normal;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    margin: 24px 0;
  }
  .logo {
    width: 104px;
    height: 100px;
  }
  .bnt-login__form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 24px;
    border-radius: $borderRadius;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    h1 {
      margin: 0;
    }
  }
  .dc-modal__actions {
    padding: 0;
  }
}

.bnt-operator__home {
  .dc-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  h1 {
    color: $gray60;
    margin: 3px 0;
  }
  .info {
    font-size: 14px;
    .call-status {
      border-radius: 20px;
      padding: 4px 12px;
      cursor: pointer;
      &.active {
        background-color: #744fc8;
        color: #fff !important;
      }
    }
    span {
      padding-right: 40px;
    }
    strong {
      font-weight: 500;
    }
  }
  @media screen and (max-width: 720px) {
    .info {
      display: flex;
      flex-direction: column;
      span {
        margin-bottom: 8px;
      }
    }
  }
}

.bnt-client__details {
  h1,
  h2 {
    color: $gray80;
  }
}

.bnt-operator__statistic,
.bnt-statistic {
  .statistic-action {
    margin-bottom: 16px;
    .dc-row {
      width: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
  .statistic-content {
    padding: 0 16px;
    min-height: 300px;
    border-radius: $borderRadius;
    background-color: #fff;
  }
}

.bnt-list-page__wrapper {
  .title {
    margin: 0;
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    dc-button {
      margin-left: 8px;
    }
  }
}

.bnt-card-wrapper {
  display: flex;
  .card {
    display: flex;
    align-items: center;
    background: #fff;
    width: fit-content;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 8px 12px;
    margin-right: 8px;
    font-size: 14px;
    .card-action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      margin-left: 16px;
      background: $gray22;
      border-radius: 50%;
      .dc-icon {
        font-size: 10px !important;
        color: #fff;
      }
    }
  }
}

.bnt-unauthorized__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// Modals

.bnt-modal {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  h2 {
    color: $gray80;
  }
}

//Dark theme

body {
  &.dc-dark {
    color: $gray10;
    background: $darkPrimary;
    .dc-header {
      background: $darkMedium;
      .user {
        color: $primary60;
        &:hover {
          color: $gray10;
          background-color: $primary60;
        }
      }
      .user-navigation {
        background-color: $darkPrimaryDarker;
      }
    }
    .bnt-card-wrapper {
      .card {
        background: $darkLighter;
        .card-action {
          background: $darkLighter;
          .dc-icon {
            background: $darkLighter;
            font-size: 14px !important;
            &:before {
              color: $primary;
            }
          }
        }
      }
    }
    .bnt-filter__actions {
      .dc-icon {
        &.remove-close {
          background-color: $gray60;
          &:before {
            color: $darkPrimary;
          }
        }
      }
    }
    .bnt-client__card {
      background: $darkMedium;
    }
    .client__details-call {
      background: $darkMedium;
      .alert-text {
        color: $secondary;
        font-size: 14px;
        font-family: Roboto, sans-serif;
      }
      &.empty {
        vertical-align: middle;
        padding: 100px 0;
      }
    }
    .bnt-login__wrapper {
      h1 {
        color: $gray22;
      }
      .bnt-login__form {
        background-color: $darkLight;
      }
      .dc-modal__actions {
        background-color: $darkLight;
      }
    }
    .bnt-operator__home {
      .info {
        strong {
          color: $gray22;
        }
      }
    }
    .bnt-client__details {
      h1,
      h2 {
        color: $gray22;
      }
    }
    .bnt-upload-file__info {
      border-radius: 8px;
      background-color: $darkLight;
      padding: 0 16px 16px;
      span {
        font-weight: 500;
      }
    }
    .ps__rail-y {
      &:hover {
        background-color: transparent;
      }
      &.ps--clicking {
        background-color: transparent;
      }
    }
  }
}
