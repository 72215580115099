@import "variables";

dc-collapse {
  display: flex;
}

.dc-collapse__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.dc-collapse__content {
  position: relative;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  cursor: pointer;
  .dc-collapse__header {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 14px 16px;
    z-index: 901;
    .dc-collapse__actions {
      display: flex;
      align-items: center;

      .dc-collapse__action {
        display: flex;
        height: 32px;

        button {
          border: none;
          background: transparent;
          font-size: 32px;
          outline: none;
          color: $gray60;
          cursor: pointer;
        }
      }
    }
  }

  .dc-collapse__body {
    padding: 0 16px;
    .collapsed {
      display: none;
    }
  }
}
