@import "variables";

dc-accordion {
  display: flex;
}

.dc-accordions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dc-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  &.active {
    .dc-accordion__header {
      background: $primary;
      .dc-accordion__title {
        color: #fff;
      }
      .dc-accordion__actions {
        .dc-accordion__action {
          .dc-icon {
            color: #fff;
          }
        }
      }
    }
  }
  .dc-accordion__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    transition: background .15s linear;
    .dc-accordion__title {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      color: $primary;
    }
    .dc-accordion__actions {
      display: flex;
      align-items: center;
      .dc-accordion__info {
        span {
          font-size: 13px;
          color: $gray60;
        }
      }
      .dc-accordion__action {
        display: flex;
        height: 32px;
        button {
          border: none;
          background: transparent;
          font-size: 32px;
          outline: none;
          color: $gray60;
          cursor: pointer;
        }
      }
    }
  }
  .dc-accordion__body {
    padding: 16px;
  }
}
