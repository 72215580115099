@import "variables";

.dc-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  &.big {
    height: 48px;
    width: 48px;
    h2 {
      font-size: 20px;
    }
  }
  &.empty {
    background-color: $primary;
    padding: 8px;
  }
  h2 {
    font-size: 15px;
    color: #fff;
    margin: 0;
    font-weight: 400;
  }
}
