body, html {
  margin: 0;
  padding: 0;
}

router-outlet {
  display: none;
}

.dc-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .dc-header {
    flex: 0 0 auto;
  }
  main {
    overflow: auto;
    flex-grow: 1;
  }
  .dc-footer {
    height: $footer-height;
    display: flex;
    flex: 0 1 auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background-color: #313131;
    font-family: Rubik, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    z-index: 1001;

    a {
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

.dc-section {
  clear: both;
}
.dc-container {
  width: 100%;
  max-width: 1920px;
  overflow: visible;
  margin: 0 auto;
  padding: 0 24px;
  &.medium {
    max-width: 1260px;
  }
}

.dc-row {
  clear: both;
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  @each $row-space in $space-values {
    @at-root .space-#{$row-space}#{&} {
      margin-left: (-$row-space / 2) + px;
      margin-right: (-$row-space / 2) + px;
    }
  }
  + .dc-row {
    //margin-top: 1rem;
  }
  &.center {
    justify-content: center;
  }
  &.end {
    justify-content: flex-end;
  }
  &.around {
    justify-content: space-around;
  }
  &.between {
    justify-content: space-between;
  }
  &.middle {
    align-items: center;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.reverse {
    flex-direction: column-reverse;
  }
  &.full-screen {
    height: 100vh;
  }
  >.dc-col {
    min-width: 0;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @each $row-space in $space-values {
      @at-root .space-#{$row-space}#{&} {
        padding-left: ($row-space / 2) + px;
        padding-right: ($row-space / 2) + px;
      }
    }
  }
}

.dc-col {
  margin-top: 1rem;
  width: 100%;
  @each $col-space in $space-values {
    @at-root .vertical-space-#{$col-space}#{&} {
      margin-bottom: $col-space + px;
    }
  }
  &.start {
    justify-content: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.end {
    align-items: flex-end;
  }
  &.around {
    flex-direction: row;
    justify-content: space-around;
  }
  &.between {
    flex-direction: row;
    justify-content: space-between;
  }
  &.middle {
    justify-content: center;
  }
  &.top {
    align-items: flex-start;
  }
  &.bottom {
    justify-content: flex-end;
  }
  &.reverse {
    flex-direction: column-reverse;
  }

  &.xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  &.xs-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  &.xs-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  &.xs-3 {
    flex-basis: 25%;
    max-width: 25%;
  }
  &.xs-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  &.xs-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  &.xs-6 {
    flex-basis: 50%;
    max-width: 50%;
  }
  &.xs-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  &.xs-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  &.xs-9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  &.xs-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  &.xs-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  &.xs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  &.xs-offset-1 {
    margin-left: 8.333%;
  }
  &.xs-offset-2 {
    margin-left: 16.667%;
  }
  &.xs-offset-3 {
    margin-left: 25%;
  }
  &.xs-offset-4 {
    margin-left: 33.333%;
  }
  &.xs-offset-5 {
    margin-left: 41.667%;
  }
  &.xs-offset-6 {
    margin-left: 50%;
  }
  &.xs-offset-7 {
    margin-left: 58.333%;
  }
  &.xs-offset-8 {
    margin-left: 66.667%;
  }
  &.xs-offset-9 {
    margin-left: 75%;
  }
  &.xs-offset-10 {
    margin-left: 83.333%;
  }
  &.xs-offset-11 {
    margin-left: 91.667%;
  }
}

@media only screen and (min-width: 38em) {
  .dc-col {
    &.sm {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }

    &.sm-1 {
      flex-basis: 8.333%;
      max-width: 8.333%;
    }

    &.sm-2 {
      flex-basis: 16.667%;
      max-width: 16.667%;
    }

    &.sm-3 {
      flex-basis: 25%;
      max-width: 25%;
    }

    &.sm-4 {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }

    &.sm-5 {
      flex-basis: 41.667%;
      max-width: 41.667%;
    }

    &.sm-6 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &.sm-7 {
      flex-basis: 58.333%;
      max-width: 58.333%;
    }

    &.sm-8 {
      flex-basis: 66.667%;
      max-width: 66.667%;
    }

    &.sm-9 {
      flex-basis: 75%;
      max-width: 75%;
    }

    &.sm-10 {
      flex-basis: 83.333%;
      max-width: 83.333%;
    }

    &.sm-11 {
      flex-basis: 91.667%;
      max-width: 91.667%;
    }

    &.sm-12 {
      flex-basis: 100%;
      max-width: 100%;
    }

    &.sm-offset-1 {
      margin-left: 8.333%;
    }

    &.sm-offset-2 {
      margin-left: 16.667%;
    }

    &.sm-offset-3 {
      margin-left: 25%;
    }

    &.sm-offset-4 {
      margin-left: 33.333%;
    }

    &.sm-offset-5 {
      margin-left: 41.667%;
    }

    &.sm-offset-6 {
      margin-left: 50%;
    }

    &.sm-offset-7 {
      margin-left: 58.333%;
    }

    &.sm-offset-8 {
      margin-left: 66.667%;
    }

    &.sm-offset-9 {
      margin-left: 75%;
    }

    &.sm-offset-10 {
      margin-left: 83.333%;
    }

    &.sm-offset-11 {
      margin-left: 91.667%;
    }
  }
}

@media only screen and (min-width: 62em) {
  .dc-col {
    &.md {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }

    &.md-1 {
      flex-basis: 8.333%;
      max-width: 8.333%;
    }

    &.md-2 {
      flex-basis: 16.667%;
      max-width: 16.667%;
    }

    &.md-3 {
      flex-basis: 25%;
      max-width: 25%;
    }

    &.md-4 {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }

    &.md-5 {
      flex-basis: 41.667%;
      max-width: 41.667%;
    }

    &.md-6 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &.md-7 {
      flex-basis: 58.333%;
      max-width: 58.333%;
    }

    &.md-8 {
      flex-basis: 66.667%;
      max-width: 66.667%;
    }

    &.md-9 {
      flex-basis: 75%;
      max-width: 75%;
    }

    &.md-10 {
      flex-basis: 83.333%;
      max-width: 83.333%;
    }

    &.md-11 {
      flex-basis: 91.667%;
      max-width: 91.667%;
    }

    &.md-12 {
      flex-basis: 100%;
      max-width: 100%;
    }

    &.md-offset-1 {
      margin-left: 8.333%;
    }

    &.md-offset-2 {
      margin-left: 16.667%;
    }

    &.md-offset-3 {
      margin-left: 25%;
    }

    &.md-offset-4 {
      margin-left: 33.333%;
    }

    &.md-offset-5 {
      margin-left: 41.667%;
    }

    &.md-offset-6 {
      margin-left: 50%;
    }

    &.md-offset-7 {
      margin-left: 58.333%;
    }

    &.md-offset-8 {
      margin-left: 66.667%;
    }

    &.md-offset-9 {
      margin-left: 75%;
    }

    &.md-offset-10 {
      margin-left: 83.333%;
    }

    &.md-offset-11 {
      margin-left: 91.667%;
    }
  }
}

@media only screen and (min-width: 75em) {
  .dc-col {
    &.lg {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }

    &.lg-1 {
      flex-basis: 8.333%;
      max-width: 8.333%;
    }

    &.lg-2 {
      flex-basis: 16.667%;
      max-width: 16.667%;
    }

    &.lg-3 {
      flex-basis: 25%;
      max-width: 25%;
    }

    &.lg-4 {
      flex-basis: 33.333%;
      max-width: 33.333%;
    }

    &.lg-5 {
      flex-basis: 41.667%;
      max-width: 41.667%;
    }

    &.lg-6 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &.lg-7 {
      flex-basis: 58.333%;
      max-width: 58.333%;
    }

    &.lg-8 {
      flex-basis: 66.667%;
      max-width: 66.667%;
    }

    &.lg-9 {
      flex-basis: 75%;
      max-width: 75%;
    }

    &.lg-10 {
      flex-basis: 83.333%;
      max-width: 83.333%;
    }

    &.lg-11 {
      flex-basis: 91.667%;
      max-width: 91.667%;
    }

    &.lg-12 {
      flex-basis: 100%;
      max-width: 100%;
    }

    &.lg-offset-1 {
      margin-left: 8.333%;
    }

    &.lg-offset-2 {
      margin-left: 16.667%;
    }

    &.lg-offset-3 {
      margin-left: 25%;
    }

    &.lg-offset-4 {
      margin-left: 33.333%;
    }

    &.lg-offset-5 {
      margin-left: 41.667%;
    }

    &.lg-offset-6 {
      margin-left: 50%;
    }

    &.lg-offset-7 {
      margin-left: 58.333%;
    }

    &.lg-offset-8 {
      margin-left: 66.667%;
    }

    &.lg-offset-9 {
      margin-left: 75%;
    }

    &.lg-offset-10 {
      margin-left: 83.333%;
    }

    &.lg-offset-11 {
      margin-left: 91.667%;
    }
  }
}
