
//Colors

$primary: #744fc8;
$primaryDarker: #51368d;
$primary20: #e4dcf4;
$primary60: #9064f4;
$secondary: #eb4378;
$secondary20: #fbd9e4;
$gray80: #313131;
$gray60: #757575;
$gray22: #C6C6C6;
$gray10: #E3E3E3;
$gray5: #F1F1F1;

$success: #3798f9;
$danger: #FF2020;
$warning: #ffa931;
$warningLight: #ffeed6;
$noticeDark: #3B3B52;
$successDark: #744FC8;
$dangerDark: #FF2020;
$warningDark: #FFA931;

$primaryText: #1c1c1c;

$blueGradientSubHeader: linear-gradient(to top, #3798f9, #247cd4);
$gradient: linear-gradient(to top, #744fc8, #e5044a);
$blueGradientDark:  linear-gradient(to top, #07519b, #07519b);


$headerHeight: 70px;

//Border
$borderRadius: 8px;

//Fonts
$icons: dc-icons, sans-serif;

$lineHeight: 24px;
$letterSpacing: 4px;

//Transition
$transition: all 200ms ease;

//Grid
$gutter-width: 1280px;
$space-values: 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52;

//Header / Footer

$header-height: 80px;
$subheader-height: 48px;
$footer-height: 40px;

/* Grid ranges */
$laptop-screen: 1441px;
$tablet-screen: 992px;
$large-phone-screen: 580px;
$phone-screen: 370px;


//DARK

$darkPrimaryDarker: #1B1B28;
$darkPrimary: #232331;
$darkMedium: #2E2E3F;
$darkLight: #3B3B52;
$darkLighter: #43435E;
$darkLightest: #4B4B6B;
$darkSuperLight: #575775;
