@import "variables";
.dc-file-auto__upload-progress {
  border-radius: $borderRadius;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow: hidden;
  &.small {
    width: 375px;
    @media (max-width: 415px) {
      width: auto;
      margin: 0 20px;
    }
  }
  .dc-file-auto__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    position: relative;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    > h2 {
      font-size: 18px;
    }
  }
  .dc-file-auto__body {
    padding: 24px;
    min-height: 200px;
    max-height: 60vh;
    background-color: #fff;
    overflow: hidden;
    .dc-file__list {
      overflow: hidden;
      height: auto;
      margin-bottom: 8px;
      &.multiple {
        height: 250px;
      }
      .dc-file__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        background-color: #f1f1f1;
        margin-bottom: 8px;
        padding: 8px;
      }
    }
    .dc-file__uploader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .dc-file-auto__footer {
    display: flex;
    justify-content: flex-end;
    border-radius: 0 0 8px 8px;
    padding: 20px;
    button {
      background: transparent;
      border: none;
      outline: none;
      font-size: 15px;
      color: $primary;
      cursor: pointer;
    }
  }
}
