@import "../sass/variables";

body {
  &.mobile {
    .dc-switch {
      .dc-switch__slider {
        &:before {
          top: -4px;
        }
      }
    }
  }
}

dc-switch {
  display: flex;
  align-items: center;
}

.dc-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  &.disabled {
    opacity: 0.6;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .dc-switch__slider {
    position: absolute;
    cursor: pointer;
    top: 6px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    background-color: #ccc;
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      top: -6px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
      border: 2px solid $gray22;
    }
  }
  input:checked + .dc-switch__slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background: $primary;
    border: 2px solid $primary;
  }
}
.dc-switch__label {
  padding-right: 16px;
}
