dc-datepicker-mobile {
  width: 100%;
}
.dc-datepicker-mobile {
  dc-slide {
    &:first-child:before {
      content: 'Nedostupno';
      position: absolute;
      top: 0;
      left: -110px;
      width: 100px;
      height: 60px;
      margin-top: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 2px solid;
      color: #c6c6c6;
      font-size: 10px;
      text-transform: uppercase;
    }

    &:last-child:after {
      content: 'Nema budućih termina';
      position: absolute;
      top: 0;
      right: -130px;
      width: 120px;
      height: 60px;
      margin-top: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 2px solid;
      color: #c6c6c6;
      font-size: 10px;
      text-transform: uppercase;
      padding-left: 16px;
    }
  }
  .swiper-container {
    min-height: 100px;
  }
  dc-slide {
    .slide-wrapper {
      width: 100%;
      height: 104px;
      margin: 0;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    color: #fff;
    top: -4px;
    outline: none;
    margin-top: 0;
  }
  .swiper-button-next {
    right: -6px;
  }
  .swiper-button-prev {
    left: -6px;
  }
}

.month {
  width: 100%;
  padding: 8px 0;
  font-family: Prompt, sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.week {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .date {
    text-align: center;

    .week-day {
      margin: 4px;
      font-family: Prompt, sans-serif;
      font-size: 10px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.8);
    }

    .month-day {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.4);

      transition: all .3s ease-in;

      &:after {
        content: '';
        position: absolute;
        transition: all .3s ease-in;
        display: block;
        top: 20px;
        left: 5px;
      }
    }

    &.past {
      .month-day:after {
        width: 75%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: rotate(-45deg);
      }
    }

    &.today {
      .month-day:after {
        top: 0;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }

    &.available {
      .month-day {
        border: 2px solid #ffffff;
        color: #ffffff;
      }
    }

    &.selected {
      .month-day {
        border: 2px solid #ffffff;
        background-color: #ffffff;
        color: #3798f9;
      }
    }

    &:nth-child(6), &:nth-child(7) {
      .week-day {
        color: #ffa931;
      }
    }
  }
}

.dc-button__first-available-date {
  color: #fff;
  text-align: center;
  font-size: 15px;
  margin: 16px 0 8px 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
