@import "variables";

body {
  &.dc-dark {
    .dc-loading {
      background-color: $darkPrimary;
    }
  }
}

.dc-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999999;
  background-color: #f4f3f7;
  @extend .center-flex;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms ease;
  .dc-spinner {
    max-width: 40px;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: $primary;
    border-radius: 100%;
    animation: scale-out 1.0s infinite ease-in-out;
  }
  &.on {
    opacity: 1;
    pointer-events: auto;
  }
}

@-webkit-keyframes scale-out {
  0% {
    -webkit-transform: scale(0)
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scale-out {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
}
