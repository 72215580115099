@import "variables";
body{
  &.dc-dark {
    ckeditor{
      .ck-toolbar {
        background: $darkPrimary !important;
        border-color: $darkSuperLight !important;
        .ck-button__label, .ck-icon {
          color: $primary !important;
        }
        .ck-toolbar__separator {
          background-color: $primary !important;
        }
      }
      .ck-content {
        background-color: $darkLight;
        border-color: $darkLightest !important;
        color: $gray60;
      }
      .ck-list, .ck-color-table {
        background-color: $darkLight;
        .ck-on {
          background-color: $primary !important;
          span {
            &.ck-button__label {
              color: $gray22 !important;
            }
          }
        }
      }
    }
  }
}

ckeditor {
  .ck-toolbar {
    --ck-border-radius: 8px 8px 0 0;
    border: 1px solid $gray22 !important;
    background: $gray22 !important;
    height: 40px;
  }
  .ck-content {
    --ck-border-radius: 0 0 8px 8px;
    font-size: 14px;
    font-family: Rubik, sans-serif;
    color: $gray80;
    &.ck-editor__editable_inline {
      border: 1px solid $gray22;
      border-top: none !important;
    }
  }
  .ck-horizontal-line {
    hr {
      background-color: #000;
      height: 1px;
    }
  }
  &.focused {
    &:not(.error, .readOnly) {
      .ck-toolbar {
        border: 1px solid $primary !important;
        border-bottom: none !important;
        box-shadow: 0px 0px 0px 0.5px $primary;
      }

      .ck-content {
        &.ck-focused {
          border: 1px solid $primary !important;
          border-top: none !important;
          box-shadow: 0 0 0 0.5px $primary;
        }
      }
    }
  }
  &.readOnly {
    .ck-toolbar {
      display: none;
    }
    .ck-content {
      background: transparent;
      border: none;
      pointer-events: none;
      &.ck-focused {
        border: none !important;
        border-top: none !important;
        box-shadow: none !important;
      }
    }
  }
  &.error {
    .ck-toolbar {
      border: 1px solid $secondary !important;
      border-bottom: none !important;
      box-shadow: 0px 0px 0px 0.5px $secondary;
    }
    .ck-content {
      background: $secondary20 !important;
      border: 1px solid $secondary !important;
      border-top: none !important;
      box-shadow: 0px 0px 0px 0.5px $secondary;
    }
  }
  &.disabled {
    .ck-content {
      background: $gray10 !important;
    }
  }
}
.ck-content {
  .table {
    width: 100%;
  }
}

.dc-text-editor {
  .dc-text-editor__label {
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: $gray60;
    font-weight: 500;
    font-family: Rubik, sans-serif;
    margin-bottom: 12px;
    .required {
      color: $secondary;
      margin-left: 4px;
      position: absolute;
      top: -4px;
    }
  }
  .dc-input__error-box {

    .dc-input__error {
      &.single {
        span {
          color: $secondary;
          font-size: 13px;
          line-height: 16px;
        }
      }
      &.multi {
        span {
          cursor: pointer;
          line-height: 16px;
          color: $secondary;
          font-size: 13px;
        }
        ul {
          li {
            color: $secondary;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
