@font-face {
  font-family:'dc-icons';
  src:url('../assets/fonts/icons/dc-icons.ttf');
  src:url('../assets/fonts/icons/dc-icons.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/icons/dc-icons.woff') format('woff'),
  url('../assets/fonts/icons/dc-icons.ttf') format('truetype'),
  url('../assets/fonts/icons/dc-icons.svg#icomoon') format('svg');
}

@import "variables";

.dc-icon {
  font-family: dc-icons, sans-serif;
  &.arrow-down {
    &:before {
      content: '\e92d';
    }
  }
  &.arrow-up {
    &:before {
      content: '\e937';
    }
  }
  &.arrow-left {
    &:before {
      content: '\e933';
    }
  }
  &.arrow-right {
    &:before {
      content: '\e936';
    }
  }
  &.chevron {
    &:before {
      content: '\e932';
    }
  }
  &.next {
    &:before {
      content: '\e934';
    }
  }
  &.end {
    &:before {
      content: '\e930';
    }
  }
  &.check-empty {
    &:before {
      content: '\e929';
    }
  }
  &.checked {
    &:before {
      content: '\e928';
    }
  }
  &.add {
    &:before {
      content: '\e938';
    }
  }
  &.edit {
    &:before {
      content: '\e93b';
    }
  }
  &.plus {
    &:before {
      content: '\e938';
    }
  }
  &.date {
    &:before {
      content: '\e925';
    }
  }
  &.time {
    &:before {
      content: '\e927';
    }
  }
  &.download {
    &:before {
      content: '\e911';
    }
  }
  &.upload {
    &:before {
      content: '\e93a';
    }
  }
  &.delete {
    &:before {
      content: '\e939';
    }
  }
  &.search {
    &:before {
      content: '\e926';
    }
  }
  &.password {
    &:before {
      content: '\e90e';
    }
  }
  &.settings {
    &:before {
      content: '\e90f';
    }
  }
  &.logout {
    &:before {
      content: '\e90d';
    }
  }
  &.show-password {
    &:before {
      content: '\e93f';
    }
  }
  &.hide-password {
    &:before {
      content: '\e93e';
    }
  }
  &.add {
    &:before {
      content: '\e906';
    }
  }
  &.loading {
    &:before {
      content: '\e944';
    }
  }
  &.menu {
    &:before {
      content: '\e945';
    }
  }
  &.indicator-application {
    &:before {
      content: '\e948';
    }
  }
  &.indicator-institution {
    &:before {
      content: '\e94b';
    }
  }
  &.indicator-website {
    &:before {
      content: '\e946';
    }
  }
  //MOBILE
  &.home {
    &:before {
      content: '\e917';
    }
  }
  &.home-selected {
    &:before {
      content: '\e918';
    }
  }
  &.appointment{
    &:before {
      content: '\e915';
    }
  }
  &.appointment-selected {
    &:before {
      content: '\e916';
    }
  }
  &.records {
    &:before {
      content: '\e91c';
    }
  }
  &.records-selected {
    &:before {
      content: '\e91d';
    }
  }
  &.profile {
    &:before {
      content: '\e919';
    }
  }
  &.profile-selected {
    &:before {
      content: '\e91a';
    }
  }
  &.radio-empty {
    &:before {
      content: '\e90b';
    }
  }
  &.radio-checked {
    &:before {
      content: '\e909';
    }
  }
  &.round-empty {
    &:before {
      content: '\e92a';
    }
  }
  &.round-checked {
    &:before {
      content: '\e92b';
    }
  }
  &.remove-close {
    &:before {
      content: '\e93d';
    }
  }
  &.gps {
    &:before {
      content: '\e91e';
    }
  }
  &.gps-active {
    &:before {
      content: '\e91f';
    }
  }
  &.star {
    &:before {
      content: '\e94e';
    }
  }
  &.star {
    &:before {
      content: '\e94e';
    }
  }
  &.star-empty {
    &:before {
      content: '\e94f';
    }
  }
  &.navigation {
    &:before {
      content: '\e940';
    }
  }
  //DOCUMENTS
  &.pdf {
    &:before {
      content: '\e922';
    }
  }
  &.video {
    &:before {
      content: '\e924';
    }
  }
  &.video-full {
    &:before {
      content: '\e94a';
    }
  }
  &.image {
    &:before {
      content: '\e921';
    }
  }
  &.documents-add {
    &:before {
      content: '\e912';
    }
  }
  &.ehr {
    &:before {
      content: '\e93c';
    }
  }
  &.print {
    &:before {
      content: '\e923';
    }
  }
  &.notes {
    &:before {
      content: '\e949';
    }
  }
  &.notes {
    &:before {
      content: '\e949';
    }
  }
  &.phone {
    &:before {
      content: '\e94b';
    }
  }
  //TRANSPORTATION
  &.bus {
    &:before {
      content: '\e901';
    }
  }
  &.subway {
    &:before {
      content: '\e903';
    }
  }
  &.trolley {
    &:before {
      content: '\e904';
    }
  }
  &.parking {
    &:before {
      content: '\e902';
    }
  }
  &.wheelchair-access {
    &:before {
      content: '\e905';
    }
  }
  //SOCIAL
  &.facebook {
    &:before {
      content: '\e907';
    }
  }
  &.instagram {
    &:before {
      content: '\e908';
    }
  }
}
