@import "variables";

body {
  &.dc-dark {
    .dc-table__classic {
      thead {
        tr > th {
          color: $darkPrimary;
        }
      }
      tbody {
        tr {
          &:hover {
            background: $primaryDarker !important;
          }
          &:nth-child(odd) {
            background: $darkMedium;
          }
          &:nth-child(even) {
            background: $darkLight;
          }
        }
      }
      &.vertical {
        thead {
          tr > th {
            color: $gray22;
          }
          tr {
            &:hover {
              background: $primaryDarker !important;
              td {
                font-weight: 300;
              }
            }
            &:nth-child(odd) {
              background: $darkMedium;
            }
            &:nth-child(even) {
              background: $darkLight;
            }
          }
        }
      }
    }
  }
}

.dc-table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 8px;
  table-layout: fixed;
  outline: $primary;
  .dc-clickable:hover {
    background-color: rgba(#000,0.02);
    transition: background-color .3s;
  }
  >tr, >tbody>tr {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    outline: $primary;
    &.color {
      td {
        outline: $primary;
        &:first-child {
          width: 8px;
          height: 100%;
          border-radius: 8px 0 0 8px !important;
          //background-color: red;
          padding: 0;
          overflow: hidden;
        }
        &:nth-child(2) {
          padding-left: 16px;
        }
      }
    }
    td {
      padding: 16px 0;
      .dc-table__label {
        font-size: 14px;
        font-weight: 500;
        font-family: Rubik, sans-serif;
        color: $gray60;
      }
      .dc-table__value {
        font-size: 14px;
        font-family: Rubik, sans-serif;
        color: $gray80;
      }
      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }
  }
  // Border Radius
  tr:first-child {
    td {
      &:first-child {
        border-radius: $borderRadius 0 0 0;
      }
      &:last-child {
        border-radius: 0 $borderRadius 0 0;
      }
      &:only-child {
        border-radius: $borderRadius $borderRadius 0 0;
      }
    }
  }
  tr:last-child {
    td {
      &:first-child {
        border-radius: 0 0 0 $borderRadius;
      }
      &:last-child {
        border-radius: 0 0 $borderRadius 0;
      }
      &:only-child {
        border-radius: 0 0 $borderRadius $borderRadius;
      }
    }
  }
  tr:only-child {
    td {
      &:only-child {
        border-radius: $borderRadius;
      }
    }
  }

  @media (max-width: $laptop-screen) {
    &.break-lap {
      display: block;
      tr, tr:first-child, tr:last-child {
        display: block;
        td, td:first-child, td:last-child {
          border: none;
          border-radius: 0;
        }
      }
      tr {
        padding: 8px 14px;
        border: 1px solid rgba(#000,0.1);
        border-radius: $borderRadius;
        position: relative;
        background-color: #fff;
        &.color {
          td {
            &:first-child {
              width: 8px !important;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
            }
            &:nth-child(2) {
              padding-left: 0;
              border-top: none !important;
            }
          }
        }
        td {
          display: block;
          padding: 14px 0;
          width: 100% !important;
          + td:not(.dc-actions):not(:first-child) {
            display: block;
            border-top: 1px solid rgba(#000,0.1);
          }
          &.dc-actions {
            display: block;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 28px;
          }
        }
        + tr {
          margin-top: 14px;
        }
        //&:hover {
        //  > td {
        //    background-color: inherit;
        //  }
        //}
      }
    }
  }
  @media (max-width: $tablet-screen) {
    &.break-tab {
      display: block;
      tr, tr:first-child, tr:last-child {
        display: block;
        td, td:first-child, td:last-child {
          border: none;
          border-radius: 0;
        }
      }
      tr {
        padding: 8px 14px;
        border: 1px solid rgba(#000,0.1);
        border-radius: $borderRadius;
        position: relative;
        background-color: #fff;
        &.color {
          td {
            &:first-child {
              width: 8px !important;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
            }
            &:nth-child(2) {
              padding-left: 0;
              border-top: none !important;
            }
          }
        }
        td {
          display: block;
          padding: 14px 0;
          width: 100% !important;
          + td:not(.dc-actions):not(:first-child) {
            display: block;
            border-top: 1px solid rgba(#000,0.1);
          }
          &.dc-actions {
            display: block;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 28px;
          }
        }
        + tr {
          margin-top: 14px;
        }
        //&:hover {
        //  > td {
        //    background-color: inherit;
        //  }
        //}
      }
    }
  }
  @media (max-width: $large-phone-screen) {
    &.break-phone {
      display: block;
      tr, tr:first-child, tr:last-child {
        display: block;
        td, td:first-child, td:last-child {
          border: none;
          border-radius: 0;
        }
      }
      tr {
        padding: 8px 14px;
        border: 1px solid rgba(#000,0.1);
        border-radius: $borderRadius;
        position: relative;
        background-color: #fff;
        &.color {
          td {
            &:first-child {
              width: 8px !important;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
            }
            &:nth-child(2) {
              padding-left: 0;
              border-top: none !important;
            }
          }
        }
        td {
          display: block;
          padding: 14px 0;
          width: 100% !important;
          + td:not(.dc-actions):not(:first-child) {
            display: block;
            border-top: 1px solid rgba(#000,0.1);
          }
          &.dc-actions {
            display: block;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 28px;
          }
        }
        + tr {
          margin-top: 14px;
        }
        //&:hover {
        //  > td {
        //    background-color: inherit;
        //  }
        //}
      }
    }
  }
  @media (max-width: $phone-screen) {
    &.break-phone {
      display: block;
      tr, tr:first-child, tr:last-child {
        display: block;
        td, td:first-child, td:last-child {
          border: none;
          border-radius: 0;
        }
      }
      tr {
        padding: 8px 14px;
        border: 1px solid rgba(#000,0.1);
        border-radius: $borderRadius;
        position: relative;
        background-color: #fff;
        &.color {
          td {
            &:first-child {
              width: 8px !important;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
            }
            &:nth-child(2) {
              padding-left: 0;
              border-top: none !important;
            }
          }
        }
        td {
          display: block;
          padding: 14px 0;
          width: 100% !important;
          + td:not(.dc-actions):not(:first-child) {
            display: block;
            border-top: 1px solid rgba(#000,0.1);
          }
          &.dc-actions {
            display: block;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 14px;
          }
        }
        + tr {
          margin-top: 14px;
        }
        //&:hover {
        //  > td {
        //    background-color: inherit;
        //  }
        //}
      }
    }
  }
}

.dc-table__wrapper {
  max-height: 310px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  &.full-height {
    max-height: calc(100vh - 80px - 250px);
    &.in-tab {
      max-height: calc(100vh - 80px - 250px - 140px);
      &.in-tab__large {
        max-height: calc(100vh - 80px - 250px - 170px);
      }
    }
  }
}

.dc-table__classic {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  outline: $primary;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  &.sortable {
    &.first-not-sortable {
      thead {
        tr > th {
          position: relative;
          cursor: pointer;
          &:after {
            transition: transform .3s linear;
          }
          &.asc {
            &:after {
              transform: rotate(-180deg);
            }
          }
            &:not(&:nth-child(1)) {
              &:after {
                content: '\e932';
                font-family: dc-icons, sans-serif;
                position: absolute;
                font-size: 18px;
                margin-left: 4px;
              }
            }
        }
      }
    }
    &.first-and-last-not-sortable {
      thead {
        tr > th {
          position: relative;
          cursor: pointer;
          &:after {
            transition: transform .3s linear;
          }
          &.asc {
            &:after {
              transform: rotate(-180deg);
            }
          }
          &:not(&:first-child) {
            &:not(&:last-child) {
              &:after {
                content: '\e932';
                font-family: dc-icons, sans-serif;
                position: absolute;
                font-size: 18px;
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
    &:not(.first-not-sortable) {
      &:not(.first-and-last-not-sortable) {
        thead {
          tr > th {
            position: relative;
            cursor: pointer;
            &:after {
              content: '\e932';
              font-family: dc-icons, sans-serif;
              position: absolute;
              font-size: 18px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
  &.not-fixed {
    table-layout: auto;
  }
  &.scrollable-body {
    thead {
      tr > th {
        position: sticky;
        top: 0;
        background: $primary;
        z-index: 1;
      }
    }
  }
  &.vertical {
    table-layout: auto;
    width: 50%;
    overflow: hidden;
    thead {
      background: transparent;
      tr > th {
        padding: 12px;
        color: $gray80;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
      }
      tr {
        &:hover {
          background: $primary20 !important;
          td {
            font-weight: 300;
          }
        }
        td {
          min-width: 100px;
          text-align: right;
          padding: 0 12px;
        }
        &:nth-child(odd) {
          background: #fff;
        }
        &:nth-child(even) {
          background: $gray5;
        }
      }
    }
  }
  .dc-checkbox {
    input {
      height: 16px;
      &::before {
        font-size: 16px;
        height: 16px;
      }
      &.checked {
        &::after {
          font-size: 16px;
          height: 16px;
        }
      }
    }
  }
  tr {
    outline: none;
  }
  thead {
    background: $primary;
    tr > th {
      padding: 12px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  }
  tbody {
    .dc-icon {
      font-size: 20px !important;
    }
    tr {
      &:hover {
        background: $primary20 !important;
        td {
          font-weight: 300;
        }
      }
      &:nth-child(odd) {
        background: #fff;
      }
      &:nth-child(even) {
        background: $gray5;
      }
    }
    tr > td {
      padding: 12px;
      font-size: 14px;
      font-weight: 300;
      text-align: left;
    }
  }
}
