@import "variables";

body {
  &.dc-dark {
    .dc-progress__steps {
      background-color: $primaryDarker;
      .progress {
        background: $primary;
      }
      .step {
        background: $darkPrimary;
        border-color: $primary;
      }
    }
  }
}

.dc-progress__steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 5px;
  background-color: $gray5;
  width: 100%;
  margin-bottom: 32px;
  .progress {
    background: $primary;
    height: 5px;
    z-index: 30001;
    &.selected {
      width: calc(50% - 16px);
    }
    &.done {
      width: calc(100% - 32px);
    }
  }
  .steps {
    display: flex;
    align-items: center;
    width: 100%;
    &.last {
      width: 32px;
    }
  }
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 2px solid $gray22;
    background: #fff;
    z-index: 30002;
    cursor: pointer;
    .step-icon {
      color: $gray22;
    }
    &.selected {
      border: 2px solid $primary;
      .step-icon {
        color: $primary;
      }
    }
    &.done {
      border: 2px solid $primary;
      background: $primary;
      .step-icon {
        color: #fff;
      }
    }
  }
}
.dc-progress__bar {
  position: relative;
  height: 15px;
  width: 100%;
  background: $primary20;
  &.bar {
    .progress {
      width: 0;
      height: 100%;
      background: $primary;
    }
  }
  &.circle {
    position: relative;
    background: #fff;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .progress {
      position: relative;
      width:  80px;
      height: 80px;
      svg {
        position: relative;
        width: 80px;
        height: 80px;
        circle {
          width: 80px;
          height: 80px;
          fill: none;
          stroke-width: 8;
          stroke: $primary;
          transform: translate(5px, 5px);
          stroke-dasharray: 240;
          stroke-dashoffset: 240;
          stroke-linecap: round;
          &:nth-child(1) {
            stroke-dashoffset: 0;
            stroke: $primary20;
          }
          &:nth-child(2) {
            stroke: $primary;
          }
        }
      }
    }
  }
}

