@import "variables";

dc-file {
  dc-button {
    display: flex;
  }
}

.dc-file__upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: dashed 2px $primary20;
  cursor: pointer;
  position: relative;
  border-radius: $borderRadius;
  margin-bottom: 16px;
  &:hover {
    border: 2px solid $primary20;
    border-radius: 8px;
  }
  &.readyToDrop {
    opacity: 0.6;
  }
  &.disabled, &.readOnly {
    border: 1px dashed $gray22;
    cursor: not-allowed;
    .dc-file__label, .dc-file__icon {
      color: $gray22;
    }
  }
  .dc-file__label {
    font-family: Prompt, sans-serif;
    font-size: 15px;
    color: $primary;
    margin: 0;
  }
  .dc-file__icon {
    color: $primary;
    font-size: 32px;
    margin-bottom: 8px;
  }
}
