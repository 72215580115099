@import "../sass/variables";

body {
  &.mobile {
    .dc-radio {
      input {
        height: 32px;
        margin-right: 28px;
        &::before {
          font-size: 32px;
          height: 32px;
        }
        &:checked {
          &::after {
            font-size: 32px;
            height: 32px;
          }
        }
      }
    }
  }
  &.dc-dark {
    .dc-radio {
      input {
        &.checked {
          &::before {
            background: #fff;
          }
        }
        &::before {
          border-radius: 50%;
          overflow: hidden;
          background: $darkLight;
        }
      }
    }
  }
}

.dc-radio {
  label {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    cursor: pointer;
    &.disabled {
      opacity: 0.6;
    }
  }
  input {
    position: relative;
    height: 24px;
    margin: 0 20px 0 0;
    font-family: dc-icons, sans-serif;
    cursor: pointer;
    &::before {
      content: '\e90b';
      font-size: 24px;
      height: 24px;
      position: absolute;
      background: #fff;
      color: $gray22;
    }
    &:checked {
      &::after {
        content: '\e909';
        font-size: 24px;
        height: 24px;
        position: absolute;
        color: $primary;
      }
    }
    &.mobile {
      height: 32px;
      margin-right: 28px;
      &::before {
        font-size: 32px;
        height: 32px;
      }
      &:checked {
        &::after {
          font-size: 32px;
          height: 32px;
        }
      }
    }
    .dc-radio__label {
      font-family: Rubik, sans-serif;
      font-size: 15px;
      color: $gray80;
    }
  }
}
