@import "variables";

body {
  &.dc-dark {
    .dc-tabs__nav {
      ul {
        li {
          &.active {
            background: $darkPrimary;
          }
        }
      }
    }
  }
}

.dc-tabs {
  width: 100%;
  height: 48px;
  display: flex;
  border-radius: 10px 10px 0 0;
  background: $primary;
}

.dc-tabs__nav {
  width: 100%;
  display: flex;
  align-items: center;
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0 32px;
    height: 100%;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 24px 0 0;
      border-radius: 8px 8px 0 0;
      padding: 12px 16px;
      cursor: pointer;
      transition: background-color .3s linear;

      a {
        color: #fff;
        transition: color .3s linear;
      }
      &.active {
        background: #fff;
        a {
          color: $primary;
        }
      }
    }
  }
}

.dc-tab__content {
  padding: 16px;
}
