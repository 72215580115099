@import "variables";

body {
  &.dc-dark {
    .dc-bottom-bar {
      background-color: $darkLight;
    }
  }
}

.dc-header {
  height: $header-height;
  padding: 0 16px 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Prompt', sans-serif;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1060;
  .application {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
    .application-logo {
      width: 42px;
      height: 100%;
      margin: 0 48px 0 25px;
      background: url("../assets/images/BNT-logo.png") no-repeat center;
      background-size: contain;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    .user {
      position: relative;
      display: flex;
      align-items: center;
      height: $header-height;
      padding: 0 12px;
      color: $gray60;
      &:hover {
        background-color: #f1f1f1;
      }
      .full-name {
        margin: 0 12px;
      }
    }
    .campaign {
      position: relative;
      display: flex;
      align-items: center;
      height: $header-height;
      padding: 0 12px;
      color: $gray60;
      &:hover {
        background-color: #f1f1f1;
      }
    }
    .user-navigation {
      position: fixed;
      top: 0;
      right: -250px;
      bottom: 0;
      margin: 0;
      width: 240px;
      list-style: none;
      background-color: #fff;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      border-radius: 0 0 8px 8px;
      padding: 32px 0;
      transition: right .3s linear;
      &.show {
        right: 0;
      }
      li {
        height: 66px;
        padding: 16px 32px;
        &.active {
          a {
            color: $primary;
          }
        }
        &:hover {
          background-image: linear-gradient(to right, #eb4378 1%, #744fc8 99%);
          span, .icon:before {
            color: #fff;
          }
          &.logout {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/logout-nav-hover.svg");
                }
              }
            }
          }
          &.stats {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/stats-nav-hover.svg") !important;
                }
              }
            }
          }
          &.phone {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/calls-nav-hover.svg") !important;
                }
              }
            }
          }
          &.employees {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/employees-nav-hover.svg") !important;
                }
              }
            }
          }
          &.visits {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/visits-nav-hover.svg") !important;
                }
              }
            }
          }
          &.campaign {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/campaigns-nav-hover.svg") !important;
                }
              }
            }
          }
          &.contracts {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/contracts-nav-hover.svg") !important;
                }
              }
            }
          }
          &.blocked-numbers {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/blocked-nav-hover.svg") !important;
                }
              }
            }
          }
        }
        &.close {
          span {
            &:hover {
              color: $primary;
            }
          }
        }
        &.logout {
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/logout.svg");
              }
            }
          }
        }
        &.stats {
          &.active {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/stats-filled.svg");
                }
              }
            }
          }
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/stats.svg");
              }
            }
          }
        }
        &.phone {
          &.active {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/calls-filled.svg");
                }
              }
            }
          }
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/calls.svg");
              }
            }
          }
        }
        &.employees {
          &.active {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/employees-full.svg");
                }
              }
            }
          }
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/employees.svg");
              }
            }
          }
        }
        &.visits {
          &.active {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/visits-full.svg");
                }
              }
            }
          }
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/visits.svg");
              }
            }
          }
        }
        &.campaign {
          &.active {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/campaigns-full.svg");
                }
              }
            }
          }
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/campaigns.svg");
              }
            }
          }
        }
        &.contracts {
          &.active {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/contracts-full.svg");
                }
              }
            }
          }
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/contracts.svg");
              }
            }
          }
        }
        &.blocked-numbers {
          &.active {
            a {
              .dc-icon {
                &:before {
                  content: url("../assets/icons/blocked-full.svg");
                }
              }
            }
          }
          a {
            .dc-icon {
              &:before {
                content: url("../assets/icons/blocked.svg");
              }
            }
          }
        }
        &.no-hover {
          background-image: none;
          margin-bottom: 28px;
          span, .icon:before {
            color: $gray60;
          }
        }
        .dc-icon {
          margin-right: 12px;
          color: $gray22;
          &:hover {
            &:before {
              color: $primary;
            }
          }
          &.light-theme {
            margin-right: 0;
            &:before {
              content: url("../assets/icons/light-mode.svg");
            }
            &.active {
              &:before {
                content: url("../assets/icons/light-mode-full.svg");
              }
            }
          }
          &.dark-theme {
            margin-left: 12px;
            margin-right: 0;
            &:before {
              content: url("../assets/icons/dark-mode.svg");
            }
            &.active {
              &:before {
                content: url("../assets/icons/dark-mode-full.svg");
              }
            }
          }
        }
        a {
          display: flex;
          align-items: center;
          cursor: pointer;
          text-decoration: none;
          color: $gray60;
        }
      }
    }
  }
  .nav-toggle {
    display: flex;
    height: 32px;
    margin: 0 12px;
    align-items: center;
    cursor: pointer;
    &:after {
      content: '\e945';
      color: $gray60;
      font-family: dc-icons, sans-serif;
      font-size: 32px;
    }
  }
}

.dc-subheader {
  height: $subheader-height;
  padding: 0 8px;
  font-family: 'Prompt', sans-serif;
  background: $blueGradientSubHeader;
  z-index: 1001;
  &.no-header {
    top: 0;
  }
}

.dc-bottom-bar {
  // position: fixed;
  // bottom: 0;
  height: 64px;
  width: 100%;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 10;
}

.dc-card {
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
  &.main {
    background: #fff;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    transition: .2s linear;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    &.loading {
      background-color: $gray5;
      width: 100%;
      height: 60px;
      animation: card-loading-animation 1.2s linear infinite;
      .loading-state {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
      .color {
        background-color: $gray22;
      }
    }
    &.empty {
      background-color: $primary20;
    }
    //&:hover {
    //  padding: 16px;
    //  transform: scale(1.01);
    //}
    &.hide {
      opacity: 0;
      height: 0;
      padding: 0;
      margin-bottom: 0;
    }
    .loading-state {
      display: none;
      position: absolute;
      background-color: $gray5;
      left: 12px;
      top: 0;
      height: 100%;
      width: calc(100% - 24px);
      span {
        height: 12px;
        background: $gray22;
        width: 100%;
        &.half {
          width: 50%;
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      margin-right: 16px;
      img {
        height: 24px;
        width: 24px;
        opacity: 0.6;
      }
    }
    .title {
      font-weight: 500;
      margin-bottom: 8px;
    }
    .body {
      color: $gray60;
      font-weight: 300;
      display: inline-block;
      margin: 4px 0 8px 0;
    }
    .time {}
    .left {
      display: flex;
    }
    .right {
      padding-right: 24px;
    }
  }
}

.public {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to top, #07519b, #3798f9);
  overflow: auto;
  padding: 0 16px;

  .logo {
    margin: 32px;
  }

  .institution {
    text-align: center;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    h1 {
      margin: 16px;
      font-family: Prompt, sans-serif;
      font-size: 18px;
      color: #ffffff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
    }
  }

  .message {
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    margin: 20px -24px;
    padding: 16px 20px;

    .text {
      flex: 1;
    }

    &.error {
      background-color: rgba(255, 32, 32, 0.1);

      .text {
        color: #ff2020;
      }

      .icon:before {
        color: #ff2020 !important;
      }
    }
  }

  .form {
    max-width: 400px;
    margin: 12px auto;
    padding: 12px 24px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);

    h2 {
      margin: 16px;
      font-family: Prompt, sans-serif;
      font-size: 18px;
      text-align: center;
      color: #313131;
    }

    button {
      margin: 12px 0;
    }
  }

  .forgot-password {
    margin: 8px 0;
    font-family: Prompt, sans-serif;
    font-size: 13px;
    text-align: center;

    a {
      color: #757575;
    }
  }

  .register {
    margin: 16px 0;
    font-family: Prompt, sans-serif;
    text-align: center;

    a {
      margin-left: 5px;
      color: #2075c9;
    }
  }

  .logout {
    margin: 16px 0;
    font-family: Prompt, sans-serif;
    text-align: center;

    a {
      margin-left: 5px;
      color: #2075c9;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    font-family: Rubik, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);

    a {
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

hr {
  height: 1px;
  border: none;
  background: $gray10;
}


@media screen and (max-width: 1024px){
  .dc-header {
    height: 72px;
    &.show-nav {
      nav.application-navigation {
        display: flex;
        position: absolute;
        top: 72px;
        left: 0;
        width: 100%;
        height: calc(100% - 80px);
        flex-direction: column;
        background-color: #fff;
        opacity: 1;
        transition: height .4s, opacity .2s;
        ul {
          padding-top: 72px;
          height: 100%;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    nav.application-navigation {
      height: 0;
      opacity: 0;
      width: 0;
      overflow: hidden;
      transition: height .4s, opacity .2s;
    }
    .nav-toggle {
      display: flex;
    }
    .profile {
      cursor: pointer;
      .user {
        ul.user-navigation {
          display: block;
          width: 100%;
          position: fixed;
          top: 72px;
          left: 0;
          height: 0;
          opacity: 0;
          background: #fff;
          transition: height .4s, opacity .2s;
          overflow: hidden;
          &.show {
            height: 100%;
            opacity: 1;
          }
          li:first-child {
            margin-top: 72px;
          }
          li a {
            justify-content: center;
          }
        }
        &:hover {
          background-color: transparent;
        }
        &:after {
          content: '\e92d';
          font-family: $icons;
          font-size: 24px;
          padding-left: 20px;
          color: $gray60;
        }
      }
      .full-name {
        display: none;
      }
      .institution {
        display: flex;
        align-items: center;
        img {
          height: 60px;
        }
      }
    }
    .application {
      .application-logo {
        width: 42px;
      }
    }
  }
}
@media screen and (max-width: 38em){
  .dc-header {
    .application {
      .application-logo {
        height: 40px;
        width: 40px;
        margin: 0 4px;
      }
    }
  }
}

@keyframes card-loading-animation {
  50% {
    opacity: 0;
  }
}
