@import "variables";

body {
  &.mobile {
    .dc-modal {
      width: 80%;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 8px;
    }
  }
  &.dc-dark {
    background: $darkMedium;
    .dc-modal {
      background-color: $darkMedium;
      .dc-loading {
        background-color: $darkMedium !important;
      }
    }
    .dc-modal__header, .dc-modal__body, .dc-modal__footer, .dc-modal__actions {
      background-color: $darkMedium;
      .dc-modal__title {
        color: $gray22;
      }
      > .dc-modal__close {
        color: $gray60;
        border: none;
      }
    }
  }
}

ngb-modal-window.modal {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1060;
  background-color: rgba(#000,0.6);
  animation: modalAnimation .3s ease-in;
  .modal-dialog {
    height: 0;
    pointer-events: all;
  }
  &.click-disabled {
    pointer-events: none;
  }
}
ngb-modal-backdrop.modal-backdrop {
  position: static;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1062;
}
body.modal-open {
  position: static;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.dc-modal {
  border-radius: $borderRadius;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow: hidden;
  .dc-loading {
    background-color: #fff !important;
  }
  &.small {
    width: 375px;
  }
  &.medium {
    width: 550px;
  }
  &.large {
    width: 960px;
  }
  &.extra-large {
    width: 84vw;
  }
  .dc-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    position: relative;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    font-size: 18px;
    .dc-modal__title {
      margin: 0;
    }
    > .dc-modal__close {
      background-color: #fff;
      border: 1px solid #fff;
      font-size: 24px;
      @extend .center-flex;
      border-radius: 50%;
      cursor: pointer;
      transition: $transition;
      &:before {
        display: block;
        position: absolute;
        top: 8px;
        right: 8px;
        content: '\e93d';
        font-family: dc-icons, sans-serif;
      }
    }
  }
  .dc-modal__body {
    padding: 24px;
    max-height: 60vh;
    overflow: hidden;
    background-color: #fff;
    position: relative;
  }
  .dc-modal__footer {
    display: flex;
    justify-content: flex-end;
    border-radius: 0 0 8px 8px;
    padding: 12px 20px;
    button {
      background: transparent;
      border: none;
      outline: none;
      font-size: 15px;
      font-family: 'Prompt', sans-serif;
      color: $primary;
      cursor: pointer;
      &.cancel {
        color: $gray60;
      }
    }
  }
  &.danger {
    .dc-modal__footer {
      button {
        &.primary {
          color: $danger;
        }
      }
    }
  }
  &.warning {
    .dc-modal__footer {
      button {
        &.primary {
          color: $warning;
        }
      }
    }
  }
}

.dc-modal__actions {
  display: flex;
  position: relative;
  width: 100%;
  padding: 12px 20px;
  .dc-modal__action-left {
    width: 50%;
    dc-button {
      button {
        padding-left: 0;
      }
    }
  }
  .dc-modal__action-right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    dc-button {
      button {
        padding-right: 0;
      }
    }
  }
  .dc-modal__action-button__default, .dc-modal__action-button__cancel, .dc-modal__action-button__danger, .dc-modal__action-button__warning {
    &:hover {
      .dc-button {
        border: none;
        background-color: transparent;
      }
    }
  }
  .dc-modal__action-button__default,
  dc-button.dc-modal__action-button__default>button,
  dc-button.dc-modal__action-button__default>button>span {
    background: transparent;
    border: none;
    outline: none;
    font-size: 15px;
    color: $primary;
    cursor: pointer;
  }
  .dc-modal__action-button__cancel,
  dc-button.dc-modal__action-button__cancel>button,
  dc-button.dc-modal__action-button__cancel>button>span {
    background: transparent;
    border: none;
    outline: none;
    font-size: 15px;
    color: $gray60;
    cursor: pointer;
  }
  .dc-modal__action-button__danger,
  dc-button.dc-modal__action-button__danger>button,
  dc-button.dc-modal__action-button__danger>button>span {
    background: transparent;
    border: none;
    outline: none;
    font-size: 15px;
    color: $secondary;
    cursor: pointer;
  }
  .dc-modal__action-button__warning,
  dc-button.dc-modal__action-button__warning>button,
  dc-button.dc-modal__action-button__warning>button>span {
    background: transparent;
    border: none;
    outline: none;
    font-size: 15px;
    color: $warning;
    cursor: pointer;
  }
  dc-button {
    .dc-button {
      border: none !important;
      background: transparent !important;

      &.primary {
        color: $primary;
        span {
          color: $primary;
        }
      }

      &.danger, &.secondary {
        color: $secondary;

        span {
          color: $secondary;
        }
      }

      &.warning {
        color: $warning;

        span {
          color: $warning;
        }
      }

      &.cancel, &.outline {
        color: $gray60;
        span {
          color: $gray60;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .dc-modal {
      &.large {
        border-radius: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        .dc-modal__body {
          max-height: 100%;
          flex-grow: 1;
        }
        .dc-modal__footer {
          background-color: #fff;
          position: relative;
          width: 100%;
        }
        .dc-modal__actions {
          position: relative;
          width: 100%;
        }
      }
    }
}

@media (max-width: 590px) {
  .dc-modal {
    &.medium {
      border-radius: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      .dc-modal__body {
        max-height: 100%;
        flex-grow: 1;
      }
      .dc-modal__footer {
        background-color: #fff;
        position: relative;
        width: 100%;
      }
      .dc-modal__actions {
        position: relative;
        width: 100%;
      }
    }
  }
}

@media (max-width: 415px) {
  .dc-modal {
      &.small {
        border-radius: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        .dc-modal__body {
          max-height: 100%;
          flex-grow: 1;
        }
        .dc-modal__footer {
          position: relative;
          width: 100%;
        }
        .dc-modal__actions {
          position: relative;
          width: 100%;
        }
      }
    }
}

@keyframes modalAnimation {
  from {
    padding-top: 0;
    background-color: rgba(#000,0);
  }
  to {
    padding-top: 100px;
    background-color: rgba(#000,0.6);
  }
}
