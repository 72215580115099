@import "variables";

body {
  &.mobile {
    dc-checkbox {
      .dc-checkbox {
        input {
          height: 32px;
          margin-right: 28px;
          &::before {
            font-size: 32px;
            height: 32px;
          }
          &.checked {
            &::after {
              font-size: 32px;
              height: 32px;
            }
          }
        }
      }
    }
  }
  &.dc-dark {
    .dc-checkbox {
      input {
        &.checked {
          &::before {
            background: #fff;
          }
        }
        &::before {
          background: $darkLight;
        }
      }
    }
  }
}

.dc-checkbox {
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 15px;
    &.disabled {
      opacity: 0.6;
    }
  }
  input {
    height: 24px;
    position: relative;
    margin: 0 20px 0 0;
    font-family: dc-icons, sans-serif;
    cursor: pointer;
    &::before {
      content: '\e929';
      font-size: 24px;
      height: 24px;
      position: absolute;
      background: #fff;
      color: $gray22;
      border-radius: 8px;
      font-family: $icons;
    }
    &.checked {
      &::after {
        content: '\e928';
        font-size: 25px;
        height: 24px;
        position: absolute;
        color: $primary;
        border-radius: 8px;
        font-family: $icons;
      }
    }
    &.mobile {
      height: 32px;
      margin-right: 28px;
      &::before {
        font-size: 32px;
        height: 32px;
      }
      &.checked {
        &::after {
          font-size: 32px;
          height: 32px;
        }
      }
    }
    .dc-checkbox__label {
      font-family: Rubik, sans-serif;
      font-size: 15px;
      color: #3798f9;
    }
  }
}
